import MultiView from 'devextreme-react/multi-view';
import {useState, useEffect, useCallback, memo} from "react"
import ScrollView from 'devextreme-react/scroll-view';
import { httpRequest } from '../../../functions';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { applicationID } from '../../../api/ApplicationID';
import { convertDate } from '../../../functions/convertDate';
import Passo1 from "./EtapasBoleto/Passo1"
import Passo2 from "./EtapasBoleto/Passo2"
import Passo3 from "./EtapasBoleto/Passo3"
import Loading from '../../../CardComponents/Loading';
import "./style.css"
import HtmlFormPopup from "../../HtmlFormPopup"
import { convertKeysToLowerCase } from "../../../functions/convertKeysToLowerCase"
import { convertFieldNameToLowerCase } from "../../../functions/convertFieldNameToLowerCase"

const GerarBoletoForm = (props) => {
    const { requestsApi } = useRequestsApi()

    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        closeOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const [disabledButtonState, setDisabledButtonState] = useState(true)

    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    //lembrar de tirar os estados globais que tem o valor do tituloid
    // dentro dos passos e colocar por props
    const contentTab = (sender) =>{
        return(
            <>
                {sender.component}
            </>
        )
    }

    const hideLoadPanel = () => {
        setDisabledButtonState(false)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel =() => {
        setDisabledButtonState(true)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }

    const entityStructureFieldsDefs = [
        {
            fieldname: "_parcela",
            fieldcaption: "Parcela",
            editorType: null,
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },

        {
            fieldname: "_tipoParcela",
            fieldcaption: "Tipo parcela",
            editorType: null,
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },

        {
            fieldname: "_numerodocumento",
            fieldcaption: "Número Documento",
            editorType: null,
            format: null,
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_vencimento",
            fieldcaption: "Vencimento",
            editorType: "dxDateBox",
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_moravaloratualizacao",
            fieldcaption: "Mora Valor Atualização",
            editorType: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_moravalorparcial",
            fieldcaption: "Mora Valor Parcial",
            editorType: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_moravalorquitacao",
            fieldcaption: "Mora Valor Quitação",
            editorType: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_valor",
            fieldcaption: "Principal",
            editorType: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_fasemorataxaatualizacao",
            fieldcaption: "Taxa Mora Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseMoraTaxaParcial",
            fieldcaption: "Taxa Mora Parcial",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseMoraTaxaQuitacao",
            fieldcaption: "Taxa Mora Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_fasetaxadescricaoatualizacao",
            fieldcaption: "Taxa Descrição Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseJurosTaxaAtualizacao",
            fieldcaption: "Juros Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseMultaTaxaAtualizacao",
            fieldcaption: "Multa Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseHonorarioTaxaAtualizacao",
            fieldcaption: "Honorário Taxa Atualização",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_fasetaxadescricaoquitacao",
            fieldcaption: "Taxa Descricao Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseJurosTaxaQuitacao",
            fieldcaption: "Juros Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseMultaTaxaQuitacao",
            fieldcaption: "Multa Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseHonorarioTaxaQuitacao",
            fieldcaption: "Honorário Taxa Quitação",
            editorType: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_jurosvalorquitacao",
            fieldcaption: "Juros Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_multavalorquitacao",
            fieldcaption: "Multa Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_JurosValorAtualizacao",
            fieldcaption: "Juros Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_MultaValorAtualizacao",
            fieldcaption: "Multa Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_HonorarioSobrePrincipal",
            fieldcaption: "Honorário sobre principal",
            editorType: "dxCheckBox",
            format: null,
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_HonorarioSobreParcelasVincendas",
            fieldcaption: "Honorário Parcelas Vincendas",
            editorType: "dxCheckBox",
            format: null,
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_HonorarioValorAtualizacao",
            fieldcaption: "Honorário Atualização",
            editorType: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_honorariovalorquitacao",
            fieldcaption: "Honorário Quitação",
            editorType: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_diasatraso",
            fieldcaption: "Atraso",
            editorType: null,
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },

        {
            fieldname: "_totalvalorquitacao",
            fieldcaption: "Valor a pagar (quitação)",
            editorType: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },

        {
            fieldname: "_totalvaloratualizacao",
            fieldcaption: "Valor a pagar (atualização)",
            editorType: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_ipcavalorquitacao",
            fieldcaption: "Valor IPCA (quitação)",
            editorType: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_ipcavaloratualizacao",
            fieldcaption: "Valor IPCA (atualização)",
            editorType: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_numeroAcordo",
            fieldcaption: "Número Acordo",
            editorType: null,
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_isacordo",
            fieldcaption: "Acordo",
            editorType: 'dxCheckBox',
            format: null,
            visible: 1,
            showincolumnchooser: 1
        }

    ]

    // let dataSourceParcelasVencidasVincendas = []
    const [dataLookupState, setDataLookupState] = useState([])


    const senderFieldNamesCheckBox = ["_isacordo"]

    const [dataSourceParcelasVencidasVincendasState, setDataSourceParcelasVencidasVincendasState] = useState([])

    const [indexMultiViewItemsGerarBoleto, setIndexMultiViewItemsGerarBoleto] = useState(0)
    const [gerarBoletoDataSource, setGerarBoletoDataSource] = useState()

    const [dateBoxState, setDateBoxState] = useState(null)
    const [arraySelecionadosState, setArraySelecionadosState] = useState([])

    const [entityStructureFieldsDefsState, setEntityStructureFieldsDefsState] = useState()


    const getParcelasVencidasVincendasFunction = (dataSelecionada, customFormVisibleBoleto) => {
        setArraySelecionadosState([])
        showLoadPanel()
        let stringDate = new Date().toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
        let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')

        //junta o formato da data da segunda linha com a hora da primeira
        let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
        httpRequest('POST', requestsApi.getParcelasVencidasVincendas, requestsApi.basicAuth, {
            // entityName: "_titulo_parcela_vencidas_vincendas",
            // fieldname: "_tituloid",
            tituloId: props.tituloId,//proximaFichaDados.tituloId,
            applicationId: applicationID,
            parcelas: props.parcelasIdArray,
            tipoNegociacao: 1, //1 BOLETO (form de boleto)/ 2 ACORDO (form de negociacao),
            dataCalculo: dataSelecionada ? dataSelecionada : stringDateResult
        })
        .then((sender) => {
            if(sender === "<HTML><BODY><B>200 OK</B></BODY></HTML>"){
                setMessage("Valor de getParcelasVencidasVincendas inválido!")
                setVisible(true)
            }
            else{
                const resultSenderJson = JSON.parse(sender)
                const resultSenderJsonToLowerCase = convertKeysToLowerCase(resultSenderJson)
                convertFieldNameToLowerCase(resultSenderJsonToLowerCase)
                
                const estruturaCampos = customFormVisibleBoleto ? customFormVisibleBoleto : entityStructureFieldsDefsState
                const estruturaCamposToLowerCase = convertKeysToLowerCase(estruturaCampos)
                convertFieldNameToLowerCase(estruturaCamposToLowerCase)

                    


                //pega os campos checkbox e altera de null pra 0
                for (let c = 0; c <= resultSenderJsonToLowerCase.length - 1; c++) {
                    resultSenderJsonToLowerCase[c]["_diasatraso"] = resultSenderJsonToLowerCase[c]?._diasatrasocalculado
                    for (let i = 0; i <= senderFieldNamesCheckBox.length - 1; i++) {
                        if (resultSenderJsonToLowerCase[c][`${senderFieldNamesCheckBox[i]}`] === null) {
                            resultSenderJsonToLowerCase[c][`${senderFieldNamesCheckBox[i]}`] = 0
                        }
                    }
                }

                resultSenderJsonToLowerCase.sort((a, b) => { return b?._diasatraso - a?._diasatraso})

                let TotalValorAtualizacao = 0;
                let TotalValorQuitacao = 0;
                let TotalValor = 0;
                resultSenderJsonToLowerCase.map(val => {
                    TotalValorAtualizacao += val._totalvaloratualizacao
                    TotalValorQuitacao += val._totalvalorquitacao
                    TotalValor += val._valor
                })
                // setTotals({ _totalvaloratualizacao: TotalValorAtualizacao, _totalvalorquitacao: TotalValorQuitacao, _valor: TotalValor })
                
                setDataSourceParcelasVencidasVincendasState(resultSenderJsonToLowerCase)
                

                // props.setDataSourceParcelasVencidasVincendasState(resultSenderJsonToLowerCase)



                //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                convertDate(resultSenderJsonToLowerCase, estruturaCamposToLowerCase)
                //

                hideLoadPanel()
            }
            
        })
        .catch((error) => {
            console.log(error)
            setMessage(error.data.error)
            setVisible(true)
        })
    }


    const getColumnsDataGridParcelas = () => { 
        showLoadPanel()
        httpRequest('POST', requestsApi.getEntityFields, requestsApi.basicAuth, {
            applicationId: applicationID,
            entityName: "_titulo_parcela"
        })
        .then((sender) => {
            const resultSenderJson = JSON.parse(sender)
            const customFormVisibleBoleto = []

            for(let c = 0; c <= resultSenderJson.length - 1; c++){
                if(resultSenderJson[c]?.customformvisible?.length > 0){
                    const customFormVisibleArray = resultSenderJson[c]?.customformvisible.toLowerCase().split(";")
                    if(customFormVisibleArray.find((e) => e === "boleto")){
                        resultSenderJson[c]["visible"] = 1
                        resultSenderJson[c]["showincolumnchooser"] = 1
                        resultSenderJson[c]["format"] = resultSenderJson[c]?.fieldtype
                        customFormVisibleBoleto.push(resultSenderJson[c])
                    }
                }
            }
            
            let stringDate = new Date().toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
            let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')

            //junta o formato da data da segunda linha com a hora da primeira
            let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
                
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                applicationId: applicationID,
                entityName: "_titulo_parcela"
            })
            .then((sender) => {
                const resultSenderJson = JSON.parse(sender)?.[0]?.queryFormProperties?.[0]?.fieldsDefs
                if (JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0) {
                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                        "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                    })
                        .then((sender) => {
                            const senderDataLookup = JSON.parse(sender)

                            setDataLookupState(senderDataLookup)

                        })
                        .catch((error) => console.log(error))
                }
                const resultSenderJsonToLowerCase = convertKeysToLowerCase(resultSenderJson)
                convertFieldNameToLowerCase(resultSenderJsonToLowerCase)

                const customFormVisibleBoletoToLowerCase = convertKeysToLowerCase(customFormVisibleBoleto)
                convertFieldNameToLowerCase(customFormVisibleBoletoToLowerCase)

                const arrayFinal = []
                if(customFormVisibleBoletoToLowerCase?.length > 0){
                    const colunaDiasAtraso = resultSenderJsonToLowerCase.find((e) => e.fieldname === "_diasatraso")
                    if(colunaDiasAtraso){
                        colunaDiasAtraso["visible"] = 1
                        colunaDiasAtraso["showincolumnchooser"] = 1
                        colunaDiasAtraso["format"] = colunaDiasAtraso?.fieldtype
                        customFormVisibleBoletoToLowerCase.push(colunaDiasAtraso)
                    }

                    for(let c = 0; c <= resultSenderJsonToLowerCase.length - 1; c++){
                        const objCustomFormVisibleBoleto = customFormVisibleBoletoToLowerCase.find((e) => e.fieldname === resultSenderJsonToLowerCase[c].fieldname)
                        if(objCustomFormVisibleBoleto){
                            arrayFinal.push({...resultSenderJsonToLowerCase[c], ...objCustomFormVisibleBoleto})
                        }
                    }


                    setEntityStructureFieldsDefsState(arrayFinal)
                    getParcelasVencidasVincendasFunction(stringDateResult, arrayFinal)
                }
                else{

                    const entityStructureFieldsDefsToLowerCase = convertKeysToLowerCase(entityStructureFieldsDefs)
                    convertFieldNameToLowerCase(entityStructureFieldsDefsToLowerCase)

                    // for(let c = 0; c <= resultSenderJsonToLowerCase.length - 1; c++){
                    //     const objEntityStructureFieldsDefsState = entityStructureFieldsDefsToLowerCase.find((e) => e?.fieldname === resultSenderJsonToLowerCase[c]?.fieldname)
                        
                    //     if(objEntityStructureFieldsDefsState){
                    //         arrayFinal.push({...resultSenderJsonToLowerCase[c], ...objEntityStructureFieldsDefsState})
                    //     }
                    // }
    
                    // console.log(arrayFinal)
                    // console.log(resultSenderJsonToLowerCase)
                    // console.log(entityStructureFieldsDefsToLowerCase)

                    setEntityStructureFieldsDefsState(entityStructureFieldsDefsToLowerCase)
                    getParcelasVencidasVincendasFunction(stringDateResult, entityStructureFieldsDefsToLowerCase)
                }

                hideLoadPanel()
            })
            .catch((error) => {
                hideLoadPanel()
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
        })
        .catch((error) => {
            hideLoadPanel()
            console.log(error)
            setMessage(error.data.error)
            setVisible(true)
        })
    }

    const [dataLookup, setDataLookup] = useState()

    const [structureDataGridEnderecosState, setStructureDataGridEnderecosState] = useState()

    const [dataSourceEnderecosState, setDataSourceEnderecosState] = useState([])

    useEffect(() => {
        getColumnsDataGridParcelas()
        if(!dataLookup){
            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                "lookUpEntities": [
                    {
                        "lookUpEntity": "TipoNegociacao"
                    },
                    {
                        "lookUpEntity": "OpcaoNegociacao"
                    },
                    {
                        "lookUpEntity": "_tipo_contato"
                    },
                    {
                        "lookUpEntity": "_pontuacao_contato"
                    }
                ]
            })
                .then((sender) => {
                    const resultJson = JSON.parse(sender)
                    setDataLookup(resultJson)
                })
                .catch((error) => {
                    console.log(error)
                    setMessage(error.data.error)
                    setVisible(true)
                })
        }

        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_cadastro_enderecos"
        })
        .then((sender) => {
            const resultEntityStructureJsonRecords = JSON.parse(sender)

            setStructureDataGridEnderecosState(resultEntityStructureJsonRecords?.[0]?.queryFormProperties?.[0]?.fieldsDefs)
            
        })
        .catch((err) => {
            console.log(err)
        })


        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_cadastro_enderecos",
            "fieldName": "_cadastro_id",
            "expressao": props.clienteId
        })
        .then((sender) => {
            const resultDataEntityJsonRecords = JSON.parse(sender)?.masterEntity?.[0]?.records

            for(let c = 0; c <= resultDataEntityJsonRecords.length - 1; c++){
                if(resultDataEntityJsonRecords[c]?._ispadrao === null){
                    resultDataEntityJsonRecords[c]["_ispadrao"] = 0
                }
            }

            console.log(resultDataEntityJsonRecords)
            setDataSourceEnderecosState(resultDataEntityJsonRecords)
            
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])
   
    const [boletoBinarioId, setBoletoBinarioId] = useState()


    const multiViewItems = [
        {
            //  title: itemClicado.itemData.text
            // ,icon: itemClicado.itemData.icon
            className: "tab-sheet"
            //,icon: '/images/favicon.ico'
            ,component: 
                        <ScrollView  width='100%' height='100%'>
                            <Passo1 
                                tituloId={props.tituloId}
                                userType={"operador"}
                                showTitle={props.showTitle}
                                setIndexMultiViewItemsGerarBoleto={setIndexMultiViewItemsGerarBoleto}
                                indexMultiViewItemsGerarBoleto={indexMultiViewItemsGerarBoleto}
                                setGerarBoletoDataSource={setGerarBoletoDataSource}
                                gerarBoletoDataSource={gerarBoletoDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                dateBoxState={dateBoxState}
                                setDateBoxState={setDateBoxState}
                                entityStructureFieldsDefs={entityStructureFieldsDefsState}
                                getParcelasVencidasVincendasFunction={getParcelasVencidasVincendasFunction}
                                dataSourceParcelasVencidasVincendasState={dataSourceParcelasVencidasVincendasState}
                                dataLookup={dataLookup}
                                dataLookupState={dataLookupState}
                                loadingPanelState={loadingPanelState}
                                disabledButtonState={disabledButtonState}
                                visible={visible}
                                setVisible={setVisible}
                                message={message}
                                setMessage={setMessage}
                                arraySelecionadosState={arraySelecionadosState}
                                setArraySelecionadosState={setArraySelecionadosState}
                                marcarTodasParcelas={props.parcelasIdArray ? true : false}
                            />
                        </ScrollView>
            // ,text: Math.random()
            // ,type: itemClicado.itemData.path
        },
        {
        //     title: itemClicado.itemData.text
        //    ,icon: itemClicado.itemData.icon
           className: "tab-sheet"
           //,icon: '/images/favicon.ico'
           ,component: <ScrollView  width='100%' height='100%'>
                           <Passo2
                                tituloId={props.tituloId}
                                credorId={props.credorId}
                                clienteId={props.clienteId}
                                setIndexMultiViewItemsGerarBoleto={setIndexMultiViewItemsGerarBoleto}
                                indexMultiViewItemsGerarBoleto={indexMultiViewItemsGerarBoleto}
                                setGerarBoletoDataSource={setGerarBoletoDataSource}
                                gerarBoletoDataSource={gerarBoletoDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                dataSourceEnderecosState={dataSourceEnderecosState}
                                setDataSourceEnderecosState={setDataSourceEnderecosState}
                                structureDataGridEnderecosState={structureDataGridEnderecosState}
                                // setBoletoBinarioId={setBoletoBinarioId}
                           />
                       </ScrollView>
           ,text: Math.random()
        //    ,type: itemClicado.itemData.path
        },
        {
        className: "tab-sheet"
        ,component: 
                    <Passo3
                        tituloId={props.tituloId}
                        clienteId={props.clienteId}
                        setIndexMultiViewItemsGerarBoleto={setIndexMultiViewItemsGerarBoleto}
                        indexMultiViewItemsGerarBoleto={indexMultiViewItemsGerarBoleto}
                        setGerarBoletoDataSource={setGerarBoletoDataSource}
                        gerarBoletoDataSource={gerarBoletoDataSource}
                        hideUpfPopupForm={props.hideUpfPopupForm}
                        abertoMenuTelecob={props.abertoMenuTelecob}
                        // _boletoBinarioId={boletoBinarioId}
                    />
        ,text: Math.random()
        }
    ]

    // useEffect(() => {
    //     console.log(indexMultiViewItemsGerarBoleto)
    //     console.log(gerarBoletoDataSource)
    // }, [indexMultiViewItemsGerarBoleto, gerarBoletoDataSource])

    return(
        <div style={{height: "78vh", padding: "0.5em"}}>
            {entityStructureFieldsDefsState ? 
                (
                    <MultiView
                        items={multiViewItems} 
                        deferRendering={true}
                        itemRender={contentTab}
                        selectedIndex={indexMultiViewItemsGerarBoleto}
                        tabIndex={indexMultiViewItemsGerarBoleto}
                        repaintChangesOnly={false}
                        animationEnabled={false}
                        swipeEnabled={false}
                        showNavButtons={true}
                        scrollByContent={true}
                        // selectedItem={multiViewItems[indexMultiViewItemsGerarBoleto]}
                    />
                ) 
                : 
                (
                    <Loading />
                )
            }
        </div>
    )
}

export default GerarBoletoForm;