import { useState, useEffect } from 'react'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
import ItemTemplateLookup from './ItemTemplateLookup';

const UpfLookupImage = (props) => {

    console.log(props)

    const [selectBoxValueState, setSelectBoxValueState] = useState()
    const [chamouOnValueChanged, setChamouOnValueChanged] = useState(false)

    useEffect(() => {
        if(props.dataEntity[`${props.dataField}`] && props?.dataLookup?.find((item) => item.id === props.dataEntity[`${props.dataField}`])){
            setSelectBoxValueState(props.dataLookup.find((item) => item.id === props.dataEntity[`${props.dataField}`]))
            console.log(props.dataLookup.find((item) => item.id === props.dataEntity[`${props.dataField}`]))
        }
    }, [])

    useEffect(() => {
        if(chamouOnValueChanged){
            props.resetFormData.current.instance.updateData(`${props.entityStructureField.fieldName}`, selectBoxValueState?.id)
        }
    }, [selectBoxValueState])
    
    return(
        <>
            <SelectBox 
                items={props.dataLookup ? props.dataLookup : []}
                valueExpr={props.valueExpr}
                displayExpr={props.displayExpr}
                itemRender={(data) =>   <ItemTemplateLookup 
                                            data={data} 
                                            displayExpr={props.displayExpr}
                                            lookUpResultIcon={props.entityStructureField?.lookUpResultIcon}
                                        />
                }
                value={selectBoxValueState?.id}
                onValueChange={(e) => {
                    if(props.modeEditOn && !chamouOnValueChanged){
                        setChamouOnValueChanged(true)
                        // props.modeEditOn()
                    }

                    if(e){
                        setSelectBoxValueState(props.dataLookup.find((item) => item.id === e))
                    }
                    else{
                        setSelectBoxValueState(null)
                    }
                }}
                showClearButton={true} 
            />

            <img
                alt=""
                src={props.entityStructureField?.lookUpResultImage ? selectBoxValueState?.[props.entityStructureField?.lookUpResultImage] : selectBoxValueState?._image}
                style={{width: "300px", padding: "1em"}}
            />
        </>
    )
}

export default UpfLookupImage;