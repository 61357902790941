import React, { useEffect, useState, useRef, Suspense, memo, useCallback } from 'react';
import Loading from '../../CardComponents/Loading';
import ExportingExcel from '../../CardComponents/ExportingExcel'
import ExportingPDF from '../../CardComponents/ExportingPDF';
import RecordForm from '../RecordForm';
import './style.css';
import { httpRequest } from '../../functions'
import { v4 } from 'uuid';
import TabPanel from 'devextreme-react/tab-panel';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import 'jspdf-autotable';
import {
    applicationID,
    relationId,
    permissions
} from '../../api/ApplicationID';
import DataGrid, {
    Column,
    Selection,
    Editing,
    Summary,
    HeaderFilter,
    Lookup,
    Paging,
    Sorting,
    Search,
    SortByGroupSummaryInfo,
    Pager,
    Scrolling,
    GroupPanel,
    MasterDetail,
    ColumnChooser,
    SearchPanel,
    FilterRow,
    TotalItem,
    Format
} from 'devextreme-react/data-grid';
import ContextMenu from 'devextreme-react/context-menu';
import {
    Popup,
    ToolbarItem,
} from 'devextreme-react/popup';

import Form, {
    GroupItem,
    SimpleItem,
    ButtonItem,
} from 'devextreme-react/form';
import { UpfForeignColumn } from "../UpfComponents/UpfForeignColumn";
// import timeZoneUtils from 'devextreme/time_zone_utils'; não ta funcionando
import { useTabPanelItems } from '../../Context/TabPanelItems'
import { LoadPanel } from 'devextreme-react/load-panel';
import { useIndexTabPanelMain } from '../../Context/IndexTabPanelMain';
import { SelectBox, Toast } from 'devextreme-react';
import RecordFormPopup from '../RecordForm/RecordFormPopup';
import ScrollView from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import { useRequestsApi } from "../../Context/RequestsApi";
import { convertDate } from "../../functions/convertDate";
import WelcomeTab from '../DrawerMain/TabPanelMain/WelcomeTab';
import UpfPopup from "../../CardComponents/UpfPopup";
import { validatePermission } from "../../functions/validatePermission"
import UpfPopupCustomForm from '../../CardComponents/UpfPopupCustomForm';
import HistoricoCustomFormPopup from '../RecordForm/HistoricoCustomFormPopup';
import ConsultaBoletoForm from '../ConsultaBoletoForm';
import UpfHtmlEditor from '../../Components/UpfComponents/UpfHtmlEditor';
import { changeDataRowColor } from '../../functions/changeDataRowColor';
import { formatFunction } from '../../functions/formatFunction';
import PortalCustomForm from '../PortalCustomForm'
import PerfilOcorrenciaCustomForm from "../PerfilOcorrenciaCustomForm";
import UpfPasswordComponent from '../UpfComponents/UpfPasswordComponent';
import UpfPopupConfirmContextMenu from '../../CardComponents/UpfPopupConfirmContextMenu'
import { calculateCustomSummary } from '../../functions/calculateCustomSummary';

const dataGridRef = React.createRef();

function changeNullValuesToZero(resultGetDataEntity, checkBoxFieldNames) {
    resultGetDataEntity.masterEntity[0].records.forEach((record) => {
        checkBoxFieldNames.forEach((field) => {
            if (record[field] === null) {
                record[field] = 0;
            }
        })
    })
}

export const QueryForm = (props) => {
    const { requestsApi } = useRequestsApi()

    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const popups = {
        isVisible: false,
        message: 'Houve um erro',
    };
    const { indexTabPanelMain, setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();

    // const{queryFormStructure, setQueryFormStrucure} = useQueryFormStructure();
    const [input, setInput] = useState("");
    const [popup, setPopup] = useState(popups);
    const [IDValue, setIDValue] = useState('');
    const [entityStructure, setEntityStructure] = useState();
    const [selectBoxValue, setSelectBoxValue] = useState('');
    const [masterEntity, setMasterEntity] = useState([]);
    const [dataLookup, setDataLookup] = useState();
    const [messageError, setMessageError] = useState();
    const [teste, setTeste] = useState([]);
    const [allRecordsEnabled, setAllRecordsEnabled] = useState(true)
    const [defaultValueState, setDefaultValueState] = useState()
    const [modeEditDataGrid, setModeEditDataGrid] = useState(false)
    const [objectId, setObjectId] = useState()
    const [changeDataRowColorState, setChangeDataRowColorState] = useState(false)
    const [dataEntityEditedFields, setDataEntityEditedFields] = useState([])
    const [dataEntityEditedFieldsCount, setDataEntityEditedFieldsCount] = useState(0)
    const [states, setStates] = useState({
        toastVisible: false,
        toastType: "",
        toastMessage: ""
    });
    const [upfPopupCustomFormStates, setUpfPopupCustomFormStates] = useState({
        visible: false,
        title: null,
        formName: null,
        tarefaId: null,
        entityName: null,
        idValue: null
    })

    const checkBoxFieldNames = [];

    const [visibleConfirmContextMenuState, setVisibleConfirmContextMenuState] = useState(false)
    const [contextMenuItemState, setContextMenuItemState] = useState()

    const hidePopupConfirmContextMenu = () => {
        setVisibleConfirmContextMenuState(false)
    }

    const toastConfiguration = (visible, type, message) => {
        setStates({
            ...states,
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const onHidingToast = () => {
        setStates({ ...states, toastVisible: false })
    }

    const hideUpfPopupCustomForm = () => {
        setUpfPopupCustomFormStates({
            ...upfPopupCustomFormStates,
            visible: false
        })
    }
    const createTarefaCustomForm = (idRegistro) => {
        setUpfPopupCustomFormStates({
            visible: true,
            title: "Tarefa",
            formName: "TarefaCustomForm",
            entityName: props.entityName,
            idValue: idRegistro
        })
    }

    const [loadingState, setLodingState] = useState(true)

    const [foreignFieldDataSourceSearchState, setForeignFieldDataSourceSearchState] =useState(false)

    useEffect(() => {
        setMasterEntity(props.outrosContratosDataSource);
        return () => {}
    }, [props.outrosContratosDataSource])

    const allRecordsSearch = useCallback(async () => {
        showLoadPanel()
        const sendInformations = {
            "entityName": props.entityName
            , "fieldName": "id"
            , "expressao": "*"
            , "applicationId": applicationID
        }
        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, sendInformations).then(senderGetDataEntity => {
            hideLoadPanel()
            const resultGetDataEntity = JSON.parse(senderGetDataEntity)

            convertDate(resultGetDataEntity.masterEntity[0].records, entityStructure[0].queryFormProperties[0].fieldsDefs)
            
            changeNullValuesToZero(resultGetDataEntity, checkBoxFieldNames);

            setMasterEntity(resultGetDataEntity.masterEntity[0].records)
        }).catch((error) => {
            if (error.data.error) {
                hideLoadPanel()
                setMasterEntity("")//limpa os dados do grid depois q da 2 clique e abre o recordForm
                setMessage(error.data.error)
                setVisible(true)
            }
            else {
                hideLoadPanel()
                setVisible(true)
                setMessage(error.statusText)
            }
        })
    }, [popup, input, props.entityName, selectBoxValue, IDValue, masterEntity]);

    useEffect(() => {        
        if (!IDValue) {
            const bodyRequest = {
                entityName: props.entityName,
                applicationId: applicationID,
            }
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, bodyRequest)
            .then(senderGetEntityStructure => {
                if (senderGetEntityStructure === "<HTML><BODY><B>200 OK</B></BODY></HTML>") {
                    hideLoadPanel()
                    setMessage(`Não foi possível retornar os campos. Retorno: ${senderGetEntityStructure}`)
                    setVisible(true)
                }
                else {
                    const resultRequestStrucuture = JSON.parse(senderGetEntityStructure);
                    !resultRequestStrucuture[0]?.lookUpEntities?.find(e => e.lookUpEntity === "_funcionario") && resultRequestStrucuture[0]?.lookUpEntities?.push({ lookUpCaption: "Funcionário", lookUpEntity: "_funcionario" })
                    setEntityStructure(resultRequestStrucuture)
                    setObjectId(validatePermission(resultRequestStrucuture[0].masterEntity.objectid))

                    if (resultRequestStrucuture[0].queryFormProperties[0].allRecordsEnabled === 1) {
                        setAllRecordsEnabled(true)
                    }
                    else {
                        setAllRecordsEnabled(false)
                    }

                    //props.foreignFieldDataSource return undefined
                    //condition return -> false
                    if(props.foreignFieldDataSource && !foreignFieldDataSourceSearchState){
                        //chamar função de pesquisa pelo codigo

                        const sendInformations = {
                            "entityName": props.entityName
                            , "fieldName": 'id'
                            , "expressao": props.foreignFieldDataSource?.[0]?.id //!sintaxe error
                            , "applicationId": applicationID
                        }
                        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, sendInformations)
                            .then(senderGetDataEntity => {
                                hideLoadPanel()
                                const resultGetDataEntity = JSON.parse(senderGetDataEntity)

                                //pega os campos checkbox e altera de null pra 0
                                changeNullValuesToZero(resultGetDataEntity, checkBoxFieldNames);
                    
                                //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                                convertDate(resultGetDataEntity.masterEntity[0].records, resultRequestStrucuture[0].queryFormProperties[0].fieldsDefs)
                                setMasterEntity(resultGetDataEntity.masterEntity[0].records)
                                setForeignFieldDataSourceSearchState(true)
                            })
                            .catch((error) => {
                                console.log(error) 
                                if (error.data.error) {
                                    hideLoadPanel()
                                    setMasterEntity([])//limpa os dados do grid depois q da 2 clique e abre o recordForm
                                    setMessage(error.data.error)
                                    setVisible(true)
                                }
                                else {
                                    hideLoadPanel()
                                    setVisible(true)
                                    setMessage(error.statusText)
                                }
                            })
                    }
                    if (resultRequestStrucuture[0]["lookUpEntities"]?.length > 0) {

                        if(props.telecob){
                            let entidadesLookups = resultRequestStrucuture[0]["lookUpEntities"]

                            if(sessionStorage.getItem("lookupsEntities")){
                                const lookupsEntitiesJson = JSON.parse(sessionStorage.getItem("lookupsEntities"))
                                let temLookupNova = false
                                let lookupsNovas = []

                                for(let c = 0; c <= entidadesLookups.length - 1; c++){
                                    if(!lookupsEntitiesJson.find(e => e?.lookUpEntity === entidadesLookups[c]?.lookUpEntity)){
                                        console.log(entidadesLookups[c])
                                        lookupsEntitiesJson.push(entidadesLookups[c])
                                        temLookupNova = true
                                        lookupsNovas.push(entidadesLookups[c])
                                    }
                                }

                                if(temLookupNova){
                                    sessionStorage.setItem("lookupsEntities", JSON.stringify(lookupsEntitiesJson))
                                    httpRequest("POST",
                                    requestsApi.getLookupTable, requestsApi.basicAuth,
                                                {
                                                    "lookUpEntities": lookupsNovas
                                                }
                                    ).then((sender)=>{
                                        const senderDataLookup = JSON.parse(sender)
                                        const dataLookupsJson = JSON.parse(sessionStorage.getItem("dataLookups"))[0]

                                        const arrayChavesSenderDataLookup = Object.keys(senderDataLookup)

                                        for(let c = 0; c <= arrayChavesSenderDataLookup.length - 1; c++){
                                            dataLookupsJson[arrayChavesSenderDataLookup[c]] = senderDataLookup[arrayChavesSenderDataLookup[c]]
                                        }

                                        sessionStorage.setItem("dataLookups", JSON.stringify([dataLookupsJson]))

                                        setDataLookup(dataLookupsJson)
                                        setLodingState(false)

                                    })
                                    .catch((error)=>{
                                        console.log(error)
                                        setLodingState(false)
                                        hideLoadPanel()
                                        setMessageError(`${error}`)
                                        setPopup({
                                            ...popup
                                                ,isVisible: true
                                                ,message: "Atenção!"
                                        })
                                    })
                                }
                                else if(sessionStorage.getItem("dataLookups")){
                                    setDataLookup(JSON.parse(sessionStorage.getItem("dataLookups"))[0])
                                    setLodingState(false)
                                }

                            }
                            else{
                                httpRequest("POST",
                                    requestsApi.getLookupTable, requestsApi.basicAuth,
                                                {
                                                    "lookUpEntities": entidadesLookups
                                                }
                                    )
                                .then((sender)=>{
                                    const senderDataLookup = JSON.parse(sender)
                                    sessionStorage.setItem("lookupsEntities", JSON.stringify(entidadesLookups))
                                    sessionStorage.setItem("dataLookups", JSON.stringify([senderDataLookup]))
                                    setDataLookup(senderDataLookup)
                                    setLodingState(false)
                                })
                                .catch((error)=>{
                                    console.log(error)
                                    setLodingState(false)
                                    hideLoadPanel()
                                    setMessageError(`${error}`)
                                    setPopup({
                                        ...popup
                                            ,isVisible: true
                                            ,message: "Atenção!"
                                    })
                                })
                            }
                        }
                        else{
                            const sendLookupRequest = {
                                "lookUpEntities": resultRequestStrucuture[0].lookUpEntities
                            }
                            httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, sendLookupRequest)
                            .then(senderLookup => {
                                const resultLookup = JSON.parse(senderLookup)
                                setDataLookup(resultLookup)
                                setLodingState(false)
                            })
                            .catch((error) => {
                                if (error?.data?.error) {
                                    hideLoadPanel()
                                    setMessage(error.data.error)
                                    setVisible(true)
                                }
                                else {
                                    hideLoadPanel()
                                    setVisible(true)
                                    setMessage(error.statusText)
                                }
                                setLodingState(false)
                            })
                        }
                    }
                    else {
                        setLodingState(false)
                    }
                    if(props.trazerTodosRegistros === true){
                        allRecordsSearch()
                    }

                }
            })
            .catch((error) => {
                console.log(error)
                hideLoadPanel()
                setMessage(error.data)
                setVisible(true)

            })
            .then(() => {
                if (focusFirstField.current) {
                    setFocusFirstField()
                }
            })
        }
        return () => {}
    }, []);

    const addDataGrid = useCallback(async (onSubmitAction) => {
        showLoadPanel()
        onSubmitAction.preventDefault();

        // Retira os caracteres especiais se o input for do tipo numérico
        let expressao = "";
        let objectSelectedBoxValue = entityStructure[0].queryFormProperties[0].fieldsDefs.find((e) => e.fieldName === selectBoxValue)
        if(objectSelectedBoxValue?.rules?.find((e) => {return e.type === "NumericRule"})){
            expressao = input.replace(/\D/g, '')
        } else {
            expressao = input;
        }

        const sendInformations = {
            "entityName": props.entityName
            , "fieldName": selectBoxValue
            , "expressao": expressao
            , "applicationId": applicationID
        }
        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, sendInformations)
            .then(senderGetDataEntity => {
                hideLoadPanel()
                const resultGetDataEntity = JSON.parse(senderGetDataEntity)

                //pega os campos checkbox e altera de null pra 0
                changeNullValuesToZero(resultGetDataEntity, checkBoxFieldNames);

                //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                convertDate(resultGetDataEntity.masterEntity[0].records, entityStructure[0].queryFormProperties[0].fieldsDefs)

                setMasterEntity(resultGetDataEntity.masterEntity[0].records)
            })
            .catch((error) => {
                console.log(error)
                if (error.data.error) {
                    hideLoadPanel()
                    setMasterEntity("")//limpa os dados do grid depois q da 2 clique e abre o recordForm
                    setMessage(error.data.error)
                    setVisible(true)
                }
                else {
                    hideLoadPanel()
                    setVisible(true)
                    setMessage(error.statusText)
                }
            })
    }, [popup, input, props.entityName, selectBoxValue]);


    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }
    //---------------------------


    const closeButtonPopup = {
        text: 'Fechar'
        , icon: 'arrowright'
        , onClick: hidePopup
    };

    const allRecords = {
        icon: "download"
        , hint: "Trazer todos os registros"
        , onClick: allRecordsSearch
    }

    //estados do recordformpopup
    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState(false)
    const [popupState, setPopupState] = useState(false)
    const [stateModeEdit, setStateModeEdit] = useState(false)
    //se der problema colocar os outros estados das
    //propriedades que estão no recorformpopup

    const addButtonOptions = {
        icon: "plus"
        , hint: "Incluir um novo registro"
        // ,enabled: props.enableBatchEditing ? false : true
        , onClick: useCallback(() => {
            if (props.queryFormPopupState || props.queryFormPopupState) {
                setPopupState(true)
                setPopupRecordFormOpen(true)
                // setStateModeEdit(true)
                // props.modeEditOn()
            }

            else if (entityStructure[0].masterEntity['customInsertRecordForm'] === "templatePortalForm") {
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        'title': entityStructure[0].masterEntity.caption
                        , 'component':
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <PortalCustomForm
                                    id={Math.random()}
                                    insertId={{value: null}}
                                    entityName={props.entityName}
                                    modeEditOn={true}
                                    dataLookup={dataLookup}
                                    entityStructure={entityStructure}
                                />
                            </ScrollView>
                        , 'text': Math.random()
                        , 'icon': 'edit'
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }

            else if (entityStructure[0].masterEntity['customInsertRecordForm'] === 'boletoForm') {
                //codigo para chamar o Criar Novo Boleto

                //pegar as lookups
                // httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                //     "lookUpEntities": [
                //         {
                //             "lookUpEntity": "TipoNegociacao"
                //         },
                //         {
                //             "lookUpEntity": "OpcaoNegociacao"
                //         },
                //         {
                //             "lookUpEntity": "_tipo_contato"
                //         },
                //         {
                //             "lookUpEntity": "_pontuacao_contato"
                //         }
                //     ]
                // })
                //     .then((sender) => {
                //         const resultGetLookupTableJson = JSON.parse(sender)

                //         //para pegar o email do cliente
                //         httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                //             "applicationId": applicationID,
                //             "entityName": "_cadastro",
                //             "fieldName": "id",
                //             "expressao": item.data._clienteid
                //         })
                //             .then((sender) => {
                //                 const resultSenderJsonCliente = JSON.parse(sender)

                //                 //para pegar os telefones
                //                 httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                //                     "applicationId": applicationID,
                //                     "entityName": "_cadastro_contatos",
                //                     "fieldName": "_cadastro_id",
                //                     "expressao": item.data._clienteid
                //                 })
                //                     .then((sender) => {
                //                         const resultSenderJsonTelefones = JSON.parse(sender)

                //                         setDataSourceBoletoState({
                //                             ...dataSourceBoletoState,
                //                             telefones: resultSenderJsonTelefones.records,
                //                             boleto: item.data.id,
                //                             email: resultSenderJsonCliente.records[0]._cryptemail,
                //                             dataLookup: resultGetLookupTableJson,
                //                             masterEntityId: item.data._clienteid
                //                         })

                //                         setConsultaBoletoFormPopupState(true)
                //                     })
                //                     .catch((err) => {
                //                         console.log(err)
                //                         //gerou boleto porem n tem contatos
                //                         setDataSourceBoletoState({
                //                             ...dataSourceBoletoState,
                //                             telefones: [],
                //                             boleto: item.data.id,
                //                             email: resultSenderJsonCliente.records[0]._cryptemail,
                //                             dataLookup: resultGetLookupTableJson,
                //                             masterEntityId: item.data._clienteid
                //                         })
                //                     })
                //             })
                //             .catch((err) => { console.log(err) })
                //     })
                //     .catch((error) => {
                //         console.log(error)
                //         // setMessage(error.data.error)
                //         // setVisible(true)
                //     })
            } else if (entityStructure[0].masterEntity['customInsertRecordForm'] === "historicoForm") {
                setHistoricoCustomFormPopupVisibleState(true)
            } else if (entityStructure[0].masterEntity['customRecordForm'] === "perfilOcorrenciaForm") {                
                setTabPanelItems([
                ...tabPanelItems,
                {
                    'title': 'Perfil Ocorrência',
                    'component':
                        <ScrollView useNative={true}>                            
                                <PerfilOcorrenciaCustomForm                                    
                                    dataLookup={dataLookup}
                                    masterEntity={masterEntity}
                                    id={Math.random()}
                                    entityName={props.entityName}                            
                                    entityStructure={entityStructure}
                                    componentType="addQuery"
                                />                            
                        </ScrollView>,
                    'text': Math.random(),
                    'icon': 'edit'
                }])
            } else {
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        'title': entityStructure[0].masterEntity.caption
                        ,'tipoFormularioTelecob': props.formularioTelecob ? 'recordForm' : null
                        ,'component':
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <RecordForm
                                    id={Math.random()}
                                    insertId={{value: null}}
                                    entityName={props.entityName}
                                    modeEditOn={true}
                                    dataLookup={dataLookup}
                                    entityStructure={entityStructure}
                                    teleCobranca={props.formularioTelecob}
                                />
                            </ScrollView>
                        ,'text': Math.random()
                        ,'icon': 'edit'
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }

        }, [entityStructure, tabPanelItems, props.entityName, indexTabPanelMain, dataLookup])
    };

    const createRecordForm = useCallback((itemClicado) => {

        const tabSheetFieldName = entityStructure[0]?.masterEntity?.tabSheetFieldName

        let itemClicadoTabSheetFieldName = null

        if (entityStructure[0]?.masterEntity?.tabSheetFieldName) {
            if (itemClicado[`${tabSheetFieldName}`]?.length > 20) {
                itemClicadoTabSheetFieldName = itemClicado[`${tabSheetFieldName}`].slice(0, 20) + "..."
            }
            else {
                itemClicadoTabSheetFieldName = itemClicado[`${tabSheetFieldName}`]
            }
        }

        setTabPanelItems([
            ...tabPanelItems,
            {
                'title': tabSheetFieldName && itemClicadoTabSheetFieldName ? `${entityStructure[0].masterEntity.caption} : ${itemClicadoTabSheetFieldName}` : entityStructure[0].masterEntity.caption
                ,'tipoFormularioTelecob': props.formularioTelecob ? 'recordForm' : null
                ,'component': 
                    <ScrollView width='100%' height='100%' useNative={true}>
                        <RecordForm
                            id={Math.random()}
                            idValue={IDValue}
                            entityName={props.entityName}
                            dataLookup={dataLookup}
                            entityStructure={entityStructure}
                            teleCobranca={props.formularioTelecob}
                        />
                    </ScrollView>
                ,'text': Math.random()
                ,'icon': 'edit'
            }
        ])
        setIndexTabPanelMain(tabPanelItems.length)
    }, [IDValue, entityStructure, tabPanelItems, props.entityName, indexTabPanelMain, dataLookup])

    //função do recordformpopup
    const _recordFormPopupRef = useRef(null)

    const RenderRecordFormPopup = useCallback(() => {
        return (
            <RecordFormPopup
                type={"recordForm"}
                idValue={IDValue}
                entityName={props.entityName}
                id={Math.random()}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordFormOpen={popupRecordFormOpen}
                setPopupState={setPopupState}
                popupState={popupState}
                stateModeEdit={stateModeEdit}
                popupRecordForm={true}
                entityStructure={entityStructure}
                dataLookup={dataLookup}
                referenciaPopup={_recordFormPopupRef}
                teleCobranca={true}
                masterEntityId={props.idValue ? props.idValue : IDValue}
                masterDetailEntity={props.entityName}

                // caption={detailCaptionName}
                // keyFieldNameState={keyFieldNameState ? keyFieldNameState : props.keyFieldNameState}
                // popupRecordFormAdd={popupRecordFormAdd}
                // embebedIdValueState={embebedIdValueState}
                // embebedEntityNameState={embebedEntityNameState}
                // dataEntity={entityDataEntityState}
            />
        )
    }, [
        IDValue
        ,props.entityName
        ,popupRecordFormOpen
        ,popupState
        ,stateModeEdit
        ,entityStructure
        ,dataLookup
        ,_recordFormPopupRef
        ,props.idValue
    ])

    const actionExportPDF = useCallback(() => {
        if (dataGridRef) {
            ExportingPDF(dataGridRef, props.caption)
        }
    }, [])
    const actionExportExcel = useCallback(() => {
        if (dataGridRef) {
            ExportingExcel(dataGridRef, props.caption);
        }
    }, [])

    // const savePDFButtonOptions = {
    //     text: 'Exportar para PDF'
    //     , icon: 'exportpdf'
    //     , onClick: actionExportPDF
    // };
    const saveExcelButtonOptions = {
        text: 'Exportar para Excel'
        , icon: 'exportxlsx'
        , onClick: actionExportExcel
    };

    const [clearTabRuleState, setClearTabRuleState] = useState()
    //estado e função do fecharAbasPopup
    const [visibleFecharAbasPopup, setVisibleFecharAbasPopup] = useState(false)
    const [messageFecharAbasPopup, setMessageFecharAbasPopup] = useState("")

    const hideFecharAbas = () => {
        setVisibleFecharAbasPopup(false)
    }
    //---------------------------


    const [ocorrenciasState, setOcorrenciasState] = useState()
    const [dataSourceBoletoState, setDataSourceBoletoState] = useState()
    const [consultaBoletoFormPopupState, setConsultaBoletoFormPopupState] = useState(false)
    const [historicoCustomFormPopupVisibleState, setHistoricoCustomFormPopupVisibleState] = useState()
    const [historicoCustomFormParcelasState, setHistoricoCustomFormParcelasState] = useState([])
    const [historicoCustomFormContatosState, setHistoricoCustomFormContatosState] = useState([])
    const [arrayDataSourceNumeroContratosState, setArrayDataSourceNumeroContratosState] = useState([])



    const clearAllTabs = {
        text: "Fechar todas as abas"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessageFecharAbasPopup("Todas as abas serão fechadas! Deseja continuar?")
                setVisibleFecharAbasPopup(true)
                setClearTabRuleState("clearAllTabs")
            }
        }
    }

    const clearTabsRight = {
        text: "Fechar abas a direita"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessageFecharAbasPopup("Todas as abas a direita da atual serão fechadas! Deseja continuar?")
                setVisibleFecharAbasPopup(true)
                setClearTabRuleState("clearTabsRight")
            }
        }
    }

    const keepThisTab = {
        text: "Fechar outras abas"
        , icon: "clear"
        , onClick: () => {
            if (tabPanelItems.length > 1) {
                setMessageFecharAbasPopup("Todas as abas, com excessão da atual, serão fechadas! Deseja continuar?")
                setVisibleFecharAbasPopup(true)
                setClearTabRuleState("keepThisTab")
            }
        }
    }


    const cancelButton = {
        text: "Cancelar"
        , icon: "close"
        , onClick: hidePopup
    };

    const cancelButtonFecharAbas = {
        text: "Cancelar"
        , icon: "close"
        , onClick: hideFecharAbas
    };

    const clearTabsFunction = (rule) => {

        //fechar todas as tabs
        if (rule === "clearAllTabs") {
            setTabPanelItems([
                {
                    ...tabPanelItems,
                    icon: "home"
                    , badge: ""
                    , component: <WelcomeTab />
                    , text: Math.random()
                }
            ])
            hideFecharAbas()
        }

        //fecha todas as abas a direita
        else if (rule === "clearTabsRight" && indexTabPanelMain < tabPanelItems.length - 1) {
            setTabPanelItems(tabPanelItems.splice(0, indexTabPanelMain + 1))
            setIndexTabPanelMain(indexTabPanelMain)
            hideFecharAbas()
        }

        //fecha todas as abas menos a atual
        else if (rule === "keepThisTab") {
            setTabPanelItems([
                {
                    ...tabPanelItems,
                    icon: "home"
                    , badge: ""
                    , component: <WelcomeTab />
                    , text: Math.random()
                },
                tabPanelItems[indexTabPanelMain]
            ])
            setIndexTabPanelMain(0)
            setIndexTabPanelMain(1)
            hideFecharAbas()
        }
    }

    const clearTabsButton = {
        text: "Ok"
        // ,icon: 'arrowright'
        , onClick: () => {
            clearTabsFunction(clearTabRuleState)
        },
        useSubmitBehavior: true,
    };

    //---------LOADING------------//

    //Estados
    const loadingPanelOptions = {
        loadPanelVisible: false
        , showIndicator: true
        , shading: false
        , showPane: true
        , hideOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)


    //Funções
    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])
    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    //---------LOADING------------//

    const focusFirstField = useRef(null)
    const _toolbarRef = useRef(null)
    const setFocusFirstField = useCallback(() => {
        focusFirstField.current.instance.beginUpdate()
        focusFirstField.current.instance.focus()
        focusFirstField.current.instance.endUpdate()
    }, [])

    const getOcorrencias = (dataSouce) => {
        // const dataSouce = props.dataEntity?.masterEntity?.[0]?.records?.[0]

        httpRequest("POST", requestsApi.getWidget, requestsApi.basicAuth, {
            applicationId: applicationID,
            equipeId: dataSouce._equipeid,
            credorId: dataSouce._credorid
        })
        .then((sender) => {
            const getWidgetsResult = JSON.parse(sender)
            for(let c = 0; c <= getWidgetsResult.length - 1; c++){
                if(getWidgetsResult[c]?.widget === "tabulacaoAcionamento"){
                    setOcorrenciasState(getWidgetsResult[c]?.ocorrencias)
                    //codigo para abrir popup do ocorrencias
                    // setHistoricoCustomFormPopupVisibleState(true)
                }
            }
            // setGravouOcorrencia(false)
        })
        .catch((err) => {
            console.log(err)
            setMessageError(err?.data?.err)
            setPopup({
                ...popup
                    ,isVisible: true
                    ,message: "Atenção!" 
            })
        })
    }

    const [terminouRequestsEmLoteState, setTerminouRequestsEmLoteState] = useState(false)


    const senderFieldNamesCheckBox = ["_isacordo"]

    const getParcelasVencidasVincendasFunction = async (idSelecionadoArray) => {
        showLoadPanel()
        let TotalValorAtualizacao = 0;
        let TotalValorQuitacao = 0;
        let TotalValor = 0;
        let stringDate = new Date().toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
        let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')
        let arrayDataSourceParcelas = []
        let arrayDataSourceContatos = []
        let arrayDataSourceContratos = []
        //junta o formato da data da segunda linha com a hora da primeira
        let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
        
        if(idSelecionadoArray?.length > 0){
            const promessas = []
            for(let i = 0; i <= idSelecionadoArray.length - 1; i++){
                promessas.push(
                    await httpRequest('POST', requestsApi.getParcelasVencidasVincendas, requestsApi.basicAuth, {
                    // entityName: "_titulo_parcela_vencidas_vincendas",
                    // fieldName: "_tituloid",
                    tituloId: idSelecionadoArray[i].id,
                    applicationId: applicationID,
                    tipoNegociacao: 2, //1 BOLETO (form de boleto)/ 2 ACORDO (form de negociacao),
                    dataCalculo: stringDateResult
                    })
                    .then( async (sender) => {
                        if(sender === "<HTML><BODY><B>200 OK</B></BODY></HTML>"){
                            setMessageError("Valor de getParcelasVencidasVincendas inválido!")
                            setPopup({
                                ...popup
                                    ,isVisible: true
                                    ,message: "Atenção!" 
                            })
                        }
                        else{
                            const resultSenderJson = JSON.parse(sender)
            
                            resultSenderJson.sort((a, b) => { return b?._DiasAtraso - a?._DiasAtraso})
            
                            //pega os campos checkbox e altera de null pra 0
                            for (let c = 0; c <= resultSenderJson.length - 1; c++) {
                                for (let i = 0; i <= senderFieldNamesCheckBox.length - 1; i++) {
                                    if (resultSenderJson[c][`${senderFieldNamesCheckBox[i]}`] === null) {
                                        resultSenderJson[c][`${senderFieldNamesCheckBox[i]}`] = 0
                                    }
                                }
                            }
                            resultSenderJson.map(val => {
                                TotalValorAtualizacao += val._TotalValorAtualizacao
                                TotalValorQuitacao += val._TotalValorQuitacao
                                TotalValor += val._Valor
                            })
                            // setTotals({ _TotalValorAtualizacao: TotalValorAtualizacao, _TotalValorQuitacao: TotalValorQuitacao, _Valor: TotalValor })
                            
                            
            
                            // props.setDataSourceParcelasVencidasVincendasState(resultSenderJson)
                            arrayDataSourceParcelas = arrayDataSourceParcelas.concat(resultSenderJson)

                            // promessas.push(
                            //     await httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                            //         "applicationId": applicationID,
                            //         "entityName": "_cadastro",
                            //         "fieldName": "id",
                            //         "expressao": idSelecionadoArray[i]._clienteid,
                            //     })
                            //     .then((sender) => {
                    
                            //         const resultGetDataEntityJson = JSON.parse(sender)
                            //         arrayDataSourceContatos = arrayDataSourceContatos.concat(resultGetDataEntityJson?.detailEntity?.find((e) => e?.entityName === "_cadastro_contatos")?.records)
                            //     })
                            //     .catch((error) => {
                            //         console.log(error)
                            //     })
                            // )

                        }
                        
                    })
                    .catch((error) => {
                        console.log(error)
                        setMessageError(error.data.error)
                        setPopup({
                            ...popup
                                ,isVisible: true
                                ,message: "Atenção!" 
                        })
                    })
                )
            }

            
            for (let a = 0; a <= idSelecionadoArray?.length - 1; a++) {
                const arrayDataSourceFiltrado = arrayDataSourceParcelas.filter((e) => e._TituloID === idSelecionadoArray[a].id)
                arrayDataSourceContratos.push({
                    _cryptnumerocontrato: idSelecionadoArray[a]?._cryptnumerocontrato,
                    _tituloid: idSelecionadoArray[a]?.id,
                })
                for (let b = 0; b <= arrayDataSourceFiltrado?.length - 1; b++) {
                    arrayDataSourceFiltrado[b]["_cryptnumerocontrato"] = idSelecionadoArray[a]?._cryptnumerocontrato
                    
                }
            }
            // for (let a = 0; a <= idSelecionadoArray?.length - 1; a++) {
            //     const arrayDataSourceFiltrado = arrayDataSourceContatos.filter((e) => e._cadastro_id === idSelecionadoArray[a]._clienteid)
            //     for (let b = 0; b <= arrayDataSourceFiltrado?.length - 1; b++) {
            //         arrayDataSourceFiltrado[b]["_cryptnumerocontrato"] = idSelecionadoArray[a]?._cryptnumerocontrato
            //     }
            // }
            console.log(arrayDataSourceParcelas)
            // console.log(arrayDataSourceContatos)
            setHistoricoCustomFormParcelasState(arrayDataSourceParcelas)
            setArrayDataSourceNumeroContratosState(arrayDataSourceContratos)
            // setHistoricoCustomFormContatosState(arrayDataSourceContatos.filter((e) => e !== ""))

            const resultados = await Promise.all(promessas)
            setTerminouRequestsEmLoteState(resultados)

        }
    }

    useEffect(() => {
        if(terminouRequestsEmLoteState){
            hideLoadPanel()
            setHistoricoCustomFormPopupVisibleState(true)
        }

    }, [terminouRequestsEmLoteState])



    const onClickMenuContextItem = (item) => {
        console.log(item)
        if(!item.itemData?.askConfirm){
            if (item.itemData.componentName === "recordForm") {
                httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                    entityName: item.itemData.componentParameter,
                    applicationId: applicationID
                })
                    .then((sender) => {
                        const resultGetEntityStructureJson = JSON.parse(sender)

                        if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                            })
                                .then((sender) => {
                                    const senderDataLookup = JSON.parse(sender)

                                    setTabPanelItems([
                                        ...tabPanelItems,
                                        {
                                            title: `${item.itemData.componentParameter}`
                                            // ,icon: itemClicado.itemData.icon
                                            , component:
                                                <ScrollView width='100%' height='100%' useNative={true}>
                                                    <RecordForm
                                                        entityName={item.itemData.componentParameter}
                                                        idValue={states.selectedItens[0].id}
                                                        dataLookup={senderDataLookup}
                                                        entityStructure={resultGetEntityStructureJson}
                                                    //IDValue={states.selectedItens[0][`${sender.itemData.viewFieldName}`]}
                                                    // IDValue={props.loteId}
                                                    />
                                                </ScrollView>
                                            , text: Math.random()
                                        }
                                    ])
                                    setIndexTabPanelMain(tabPanelItems.length)

                                })
                                .catch((error) => console.log(error))
                        }
                        else {
                            setTabPanelItems([
                                ...tabPanelItems,
                                {
                                    title: `${item.itemData.componentParameter}`
                                    // ,icon: itemClicado.itemData.icon
                                    , component:
                                        <ScrollView width='100%' height='100%' useNative={true}>
                                            <RecordForm
                                                entityName={item.itemData.componentParameter}
                                                idValue={states.selectedItens[0].id}
                                                entityStructure={resultGetEntityStructureJson}
                                            //IDValue={states.selectedItens[0][`${sender.itemData.viewFieldName}`]}
                                            // IDValue={props.loteId}
                                            />
                                        </ScrollView>
                                    , text: Math.random()
                                }
                            ])
                            setIndexTabPanelMain(tabPanelItems.length)
                        }
                    })
                    .catch((error) => console.log(error))
            }
            if (item.itemData.componentName === "recordFormPopup") { 
                setPopupState(true)
                setPopupRecordFormOpen(true)
            }

            // else if (item.itemData.componentName === "htmlForm") {
            //     let idValueJson = []
            //     // for(let a = 0; a <= dataGridRef.current.instance.getSelectedRowsData()?.length-1; a++){
            //     //     idValueJson.push(states.selectedItens[a][`${item.itemData.viewFieldName}`])
            //     // }
            //     for (let a = 0; a <= dataGridRef.current.instance.getSelectedRowsData()?.length - 1; a++) {
            //         idValueJson.push(states.selectedItens[a].id)
            //     }

            //     httpRequest('POST', `${requestsApi.customUrl}${item.itemData.methodName}`, requestsApi.basicAuth,
            //         {
            //             "applicationId": applicationID,
            //             "relationId": relationId,
            //             "idValue": idValueJson,
            //             "parameter": item.itemData.componentParameter,
            //             "entityName": props.entityName
            //         })
            //         .then((sender) => {
            //             const resultJsonTemplateHtml = JSON.parse(sender)
            //             toastConfiguration(true, 'info', "Arquivo gerado com sucesso!")
            //             setHtmlTemplate(() => {
            //                 return { __html: resultJsonTemplateHtml.mensagem }
            //             })
            //             setHtmlPopupVisible(true)

            //         })
            //         .catch((error) => {
            //             console.log(error)
            //             if (error?.data?.error) {
            //                 props.hideLoadPanel()
            //                 props.setMessageError(error.data.error)
            //                 props.setPopup({
            //                     ...props.popup
            //                     , isVisible: true
            //                     , message: "Atenção!"
            //                 })
            //             }
            //         })
            // }
            else if(item.itemData.componentName === "historicoCustomForm"){
                let idValueJson = []

                for (let a = 0; a <= dataGridRef.current.instance.getSelectedRowsData()?.length - 1; a++) {
                    idValueJson.push(dataGridRef.current.instance.getSelectedRowsData()[a])
                }

                getOcorrencias(idValueJson[0])
                getParcelasVencidasVincendasFunction(idValueJson)
            }

            else if (item.itemData.methodName) {
                console.log(item.itemData.methodName)
                console.log(item.itemData.componentParameter)

                let idValueJson = []
                // for(let a = 0; a <= dataGridRef.current.instance.getSelectedRowsData()?.length -1; a++){
                //     idValueJson.push(states.selectedItens[a][`${item.itemData.viewFieldName}`])
                // }
                for (let a = 0; a <= dataGridRef.current.instance.getSelectedRowsData()?.length - 1; a++) {
                    idValueJson.push(dataGridRef.current.instance.getSelectedRowsData()[a].id)
                }

                httpRequest('POST', `${requestsApi.customUrl}${item.itemData.methodName}`, requestsApi.basicAuth,
                    {
                        "applicationId": applicationID,
                        "relationId": relationId,
                        "idValue": idValueJson,
                        "parameter": item.itemData.componentParameter,
                        "entityName": props.entityName
                    })
                    .then((sender) => {
                        const response = JSON.parse(sender)
                        toastConfiguration(true, 'info', response.mensagem)

                    })
                    .catch((error) => {
                        if (error?.data?.error) {
                            props.hideLoadPanel()
                            props.setMessageError(error.data.error)
                            props.setPopup({
                                ...props.popup
                                , isVisible: true
                                , message: "Atenção!"
                            })
                        }
                    })
            }
        }
        else{
            setContextMenuItemState(item)
            setVisibleConfirmContextMenuState(true)
        }
    }

    const openMenuContextFunction = (e) => {

        const contextMenu = entityStructure?.[0]?.queryFormProperties?.[0]?.contextMenu
        console.log(contextMenu)

       console.log(e?.row?.data)
        if (contextMenu !== null && e?.row?.data) {
            let menuContextArray = []
            for (let c = 0; c <= contextMenu?.length - 1; c++) {
                if(contextMenu[c]?.gridVisible === 1){
                    let contextMenuItem = {
                        "text": contextMenu[c].caption,
                        "icon": contextMenu[c].icon,
                        "onItemClick": onClickMenuContextItem,
                        "componentName": contextMenu[c].componentName,
                        "componentParameter": contextMenu[c].componentParameter,
                        "idFieldName": contextMenu[c].idFieldName,
                        "methodName": contextMenu[c].methodName,
                        "askConfirm": contextMenu[c].askConfirm
                    }
                    menuContextArray.push(contextMenuItem)
                }
            }

            if (e.target === "content" && dataGridRef.current.instance.getSelectedRowsData()?.length > 0) {
                if (e.row) {
                    if (e.row.rowType === "data") {
                        console.log(e.items)
                        e.items = menuContextArray
                        console.log(e.items)

                    }
                }
            }
        }
    } 

    const RenderHistoricoCustomFormPopup = memo(() => {
        return (
            <HistoricoCustomFormPopup
                visibleState={historicoCustomFormPopupVisibleState}
                functionVisibleState={setHistoricoCustomFormPopupVisibleState}
                arrayDataSourceParcelasVencidas={historicoCustomFormParcelasState}
                arrayDataSourceNumeroContratos={arrayDataSourceNumeroContratosState}
                // arrayDataSourceContatos={historicoCustomFormContatosState}
                tituloId={props.tituloId ? props.tituloId : props.idValue}
                ocorrencias={ocorrenciasState}
                toastConfiguration={toastConfiguration}
                clienteId={dataGridRef.current.instance.getSelectedRowsData()?.[0]?._clienteid}
            />
        );
    })

    const onRowDblClickRecordFormOpenFunction = (item) => {
        console.log(item.data)
        
       if (!props.telecob && (props.queryFormPopupForeignFieldState || props.queryFormPopupState)) {
            setPopupRecordFormOpen(true)
            setPopupState(true)
            setStateModeEdit(true)
        }
        else if(props.enviarSelecionado && props.telecob){
            props.enviarSelecionado(item?.data)
        }
        //permissão
        else if (!props.telecob && objectId?._show === 1) {
            if (entityStructure[0].masterEntity['customRecordForm'] === 'boletoForm') {
                //codigo para chamar o ConsultaBoletoForm

                //pegar as lookups
                httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                    "lookUpEntities": [
                        {
                            "lookUpEntity": "TipoNegociacao"
                        },
                        {
                            "lookUpEntity": "OpcaoNegociacao"
                        },
                        {
                            "lookUpEntity": "_tipo_contato"
                        },
                        {
                            "lookUpEntity": "_pontuacao_contato"
                        }
                    ]
                })
                    .then((sender) => {
                        const resultGetLookupTableJson = JSON.parse(sender)

                        //para pegar o email do cliente
                        httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                            "applicationId": applicationID,
                            "entityName": "_cadastro",
                            "fieldName": "id",
                            "expressao": item.data._clienteid
                        })
                            .then((sender) => {
                                const resultSenderJsonCliente = JSON.parse(sender)

                                //para pegar os telefones
                                httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                                    "applicationId": applicationID,
                                    "entityName": "_cadastro_contatos",
                                    "fieldName": "_cadastro_id",
                                    "expressao": item.data._clienteid
                                })
                                    .then((sender) => {
                                        const resultSenderJsonTelefones = JSON.parse(sender)

                                        setDataSourceBoletoState({
                                            ...dataSourceBoletoState,
                                            telefones: resultSenderJsonTelefones.records,
                                            boleto: item.data.id,
                                            email: resultSenderJsonCliente.records[0]._cryptemail,
                                            dataLookup: resultGetLookupTableJson,
                                            masterEntityId: item.data._clienteid
                                        })

                                        setConsultaBoletoFormPopupState(true)
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        //gerou boleto porem n tem contatos
                                        setDataSourceBoletoState({
                                            ...dataSourceBoletoState,
                                            telefones: [],
                                            boleto: item.data.id,
                                            email: resultSenderJsonCliente.records[0]._cryptemail,
                                            dataLookup: resultGetLookupTableJson,
                                            masterEntityId: item.data._clienteid
                                        })
                                    })
                            })
                            .catch((err) => { console.log(err) })
                    })
                    .catch((error) => {
                        console.log(error)
                        // setMessage(error.data.error)
                        // setVisible(true)
                    })
            }

            else if (entityStructure[0].masterEntity['customRecordForm'] === "templatePortalForm") {
                const tabSheetFieldName = entityStructure[0]?.masterEntity?.tabSheetFieldName

                let itemClicadoTabSheetFieldName = null

                if (entityStructure[0]?.masterEntity?.tabSheetFieldName) {
                    if (item.data[`${tabSheetFieldName}`]?.length > 20) {
                        itemClicadoTabSheetFieldName = item.data[`${tabSheetFieldName}`].slice(0, 20) + "..."
                    }
                    else {
                        itemClicadoTabSheetFieldName = item.data[`${tabSheetFieldName}`]
                    }
                }
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        'title': tabSheetFieldName && itemClicadoTabSheetFieldName ? `${entityStructure[0].masterEntity.caption} : ${itemClicadoTabSheetFieldName}` : entityStructure[0].masterEntity.caption
                        , 'component': <ScrollView width='100%' height='100%' useNative={true}>
                            <PortalCustomForm
                                id={Math.random()}
                                idValue={IDValue}
                                entityName={props.entityName}
                                dataLookup={dataLookup}
                                entityStructure={entityStructure}
                            />
                        </ScrollView>
                        , 'text': Math.random()
                        , 'icon': 'edit'
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }            
            else if (entityStructure[0].masterEntity['customRecordForm'] === "perfilOcorrenciaForm") {
                setTabPanelItems([
                ...tabPanelItems,
                {
                    'title': 'Perfil Ocorrência',
                    'component':
                        <ScrollView useNative={true}>                            
                                <PerfilOcorrenciaCustomForm                                   
                                    dataLookup={dataLookup}
                                    masterEntity={masterEntity}
                                    id={Math.random()}
                                    idValue={IDValue}
                                    entityName={props.entityName}                            
                                    entityStructure={entityStructure}
                                    componentType="editQuery"
                                />
                        </ScrollView>,
                    'text': Math.random(),
                    'icon': 'edit'
                }])
            }
            else if (entityStructure[0].masterEntity['customRecordForm'] === "historicoForm") {
                setHistoricoCustomFormPopupVisibleState(true)
            }
            else if (entityStructure[0].masterEntity['customRecordForm'] === 'perfilOcorrenciaCustomForm') {
                // implements
            }
            else {
                //se tem customRecordForm, se não segui o padrão
                if (entityStructure[0].masterEntity["customRecordForm"]) {
                    //tipos de customRecordForm
                    if (entityStructure[0].masterEntity["customRecordForm"] === "tarefaCustomForm") {
                        createTarefaCustomForm(item.data.id)
                    }
                }
                else {
                    createRecordForm(item.data)
                }
            }
        }
        else if(!props.telecob){
            setMessage("Usuário não tem permissão!")
            setVisible(true)
        }
    }

    const selectedItemFunction = (sender) => {
        console.log(modeEditDataGrid)
        console.log(sender.selectedRowsData?.length)
        console.log(sender.selectedRowsData[0])
        console.log(props.queryFormPopupForeignFieldState)
        sender.component.refresh(true);
        if(!modeEditDataGrid && sender.selectedRowsData?.length > 0){
            setIDValue(sender.selectedRowsData[0].id)
            if (props.queryFormPopupForeignFieldState) {
                props.setSelectItemQueryFormPopup(sender.selectedRowsData[0])
            }
        }
    }

    const recordDeleteJsonDetail = (entityNameGrid, jsonRowId) => {
        httpRequest("POST",
            requestsApi.deleteRecordFromJson,
            requestsApi.basicAuth,
            {
                "entityName": entityNameGrid,
                "applicationId": applicationID,
                "relationId": relationId,
                "fieldName": 'id',
                "expressao": jsonRowId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // setMasterEntity(senderUpdateRecordFromJson.masterEntity[0].records)
                // modeEditOff()
                toastConfiguration(true, 'success', "Campo removido com sucesso!")
            })
            .catch((error) => {
                if (error.data.error) {
                    hideLoadPanel()
                    setMessage(error.data.error)
                    setVisible(true)
                }
                else {
                    hideLoadPanel()
                    setVisible(true)
                    setMessage(error.statusText)
                }
            }
        )
    }

    const recordUpdateJsonDetail = (entityNameGrid, jsonRow) => {
        const jsonRowResult = jsonRow
        try{
            delete jsonRowResult.taComMascara
        }
        catch{
            console.log(jsonRowResult)
        }

        httpRequest("POST",
            requestsApi.updateRecordFromJson,
            requestsApi.basicAuth,
            {
                "entityName": entityNameGrid,
                "json": jsonRowResult,
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // setMasterEntity(senderUpdateRecordFromJson.masterEntity[0].records)
                // modeEditOff()
                toastConfiguration(true, 'success', "Campo editado com sucesso!")
            })
            .catch((error) => {
                if (error.data.error) {
                    hideLoadPanel()
                    setMessage(error.data.error)
                    setVisible(true)
                }
                else {
                    hideLoadPanel()
                    setVisible(true)
                    setMessage(error.statusText)
                }
            }
        )
    }

    const recordInsertJsonDetail = (entityNameGrid, jsonRow) => {
        const jsonTemp = jsonRow;
        delete jsonTemp.id
        console.log(jsonRow)
        console.log(jsonTemp)
        httpRequest("POST",
            requestsApi.insertRecordFromJson,
            requestsApi.basicAuth,
            {
                "entityName": entityNameGrid,
                "json": jsonTemp,
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // modeEditOff()
                // setMasterEntity(senderUpdateRecordFromJson?.masterEntity[0].records)
                jsonRow.id = senderUpdateRecordFromJson.idValue;
                dataGridRef.current.instance.repaint()
                dataGridRef.current.instance.refresh()
                toastConfiguration(true, 'success', "Campo adicionado com sucesso!")
            })
            .catch((error) => {
                if (error.data.error) {
                    hideLoadPanel()
                    setMessage(error.data.error)
                    setVisible(true)
                }
                else {
                    hideLoadPanel()
                    setVisible(true)
                    setMessage(error.statusText)
                }
            })
        // }
    }

    const onEditorPreparing = (e) => {
        if (e.editorName === "dxCheckBox") {
            if (e.value === null) {
                e.value = 0
            }
        }
    }


    return (
        <div className="tab-items">
            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
            />
            <ScrollView width='100%' height='100%' useNative={true}>
                <div className="divMain">
                    {popupRecordFormOpen === true ? <RenderRecordFormPopup /> : <></>}
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        visible={loadingPanelState.loadPanelVisible}
                        showIndicator={loadingPanelState.showIndicator}
                        shading={loadingPanelState.shading}
                        showPane={loadingPanelState.showPane}
                    />

                    {historicoCustomFormPopupVisibleState === true ? <RenderHistoricoCustomFormPopup /> : <></>}

                    <UpfPopup
                        typePopup="message"
                        popupVisibleState={visible}
                        popupVisibleFunctionChangeState={setVisible}
                        message={message}
                    />

                    {dataSourceBoletoState &&
                        <Popup
                            visible={consultaBoletoFormPopupState}
                            onHiding={() => {
                                setConsultaBoletoFormPopupState(false)
                            }}
                            dragEnabled={false}
                            fullScreen={true}
                            hideOnOutsideClick={false}
                            animation={null}
                            // deferRendering={false}
                            // title={props.entityStructure[0].masterEntity.caption}
                            showCloseButton={true}
                            showTitle={true}
                            title='Boleto'
                            id="popupForm"
                        >
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <ConsultaBoletoForm
                                    boletoId={dataSourceBoletoState?.boleto}
                                    email={dataSourceBoletoState?.email}
                                    telefones={dataSourceBoletoState?.telefones}
                                    dataLookup={dataSourceBoletoState?.dataLookup}
                                    masterEntityId={dataSourceBoletoState?.masterEntityId}
                                    dataSourceBoletoState={dataSourceBoletoState}
                                    consultaBoletoFormPopupState={consultaBoletoFormPopupState}
                                />
                            </ScrollView>

                        </Popup>
                    }

                    <UpfPopup
                        typePopup="closeTabs"
                        popupVisibleState={visibleFecharAbasPopup}
                        popupVisibleFunctionChangeState={setVisibleFecharAbasPopup}
                        message={messageFecharAbasPopup}
                        clearTabRuleState={clearTabRuleState}
                        toolbarRef={_toolbarRef}
                    />

                    {upfPopupCustomFormStates.visible &&
                        <UpfPopupCustomForm
                            entityName={upfPopupCustomFormStates.entityName}
                            idValue={upfPopupCustomFormStates.idValue}
                            hideUpfPopupForm={hideUpfPopupCustomForm}
                            visibleState={upfPopupCustomFormStates.visible}
                            title={upfPopupCustomFormStates.title}
                            formName={upfPopupCustomFormStates.formName}
                        />
                    }

                    {visibleConfirmContextMenuState &&
                        <UpfPopupConfirmContextMenu
                            hidePopupConfirmContextMenu={hidePopupConfirmContextMenu}
                            visibleConfirmContextMenu={visibleConfirmContextMenuState}
                            contextMenuFunction={onClickMenuContextItem}
                            contextMenuItemState={contextMenuItemState}
                        />
                    }

                    {entityStructure && objectId && !loadingState ?
                        (<>
                            {/* <div className={''}> */}
                            <div className={'dx-card'}>
                                <div className={'div-toolbar'}>
                                    <Toolbar
                                        ref={_toolbarRef}
                                    >
                                        {!props.embeddedComponents &&
                                            <Item
                                                location="before"
                                                widget={'dxButton'}
                                                options={addButtonOptions}
                                                disabled={!objectId._insert}
                                            />
                                        }

                                        <Item
                                            location="before"
                                            widget={'dxButton'}
                                            options={allRecords}
                                            disabled={!allRecordsEnabled}
                                        />

                                        {/* <Item
                                            locateInMenu="always"
                                            widget="dxButton"
                                            options={savePDFButtonOptions}
                                        /> */}

                                        <Item
                                            locateInMenu="always"
                                            widget="dxButton"
                                            options={saveExcelButtonOptions}
                                        />

                                        {!props.queryFormPopupForeignFieldState && !props.formularioTelecob &&
                                            <Item
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={clearAllTabs}
                                            />
                                        }

                                        {!props.queryFormPopupForeignFieldState && !props.formularioTelecob &&
                                            <Item
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={clearTabsRight}
                                                disabled={tabPanelItems.length === indexTabPanelMain + 1}
                                            />
                                        }

                                        {!props.queryFormPopupForeignFieldState && !props.formularioTelecob &&
                                            <Item
                                                location="after"
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={keepThisTab}
                                            />
                                        }

                                        {/* <Item
                                                locateInMenu="always"
                                                widget="dxButton"
                                                options={configForm}
                                            /> */}
                                    </Toolbar>
                                </div>

                                <form className="form-main" onSubmit={addDataGrid}>
                                    <Form ref={focusFirstField}>
                                        <GroupItem>
                                            <GroupItem colCount={4}>
                                                <SimpleItem
                                                    colSpan={1}

                                                >
                                                    {entityStructure[0].queryFormProperties[0].searchFields &&
                                                        entityStructure[0].queryFormProperties[0].defaultSearchField ? (
                                                        <>
                                                            <SelectBox
                                                                colSpan={1}
                                                                isRequired={true}
                                                                searchEnabled={true}
                                                                dataSource={entityStructure[0].queryFormProperties[0].searchFields}
                                                                displayExpr={"fieldCaption"}
                                                                valueExpr={"fieldName"}
                                                                defaultValue={entityStructure[0].queryFormProperties[0].defaultSearchField}
                                                                // entityStructure[0].queryFormProperties[0].searchFields[0].fieldName
                                                                // entityStructure[0].queryFormProperties[0].defaultSearchField
                                                                onValueChanged={(sender) => {
                                                                    setSelectBoxValue(sender.value)
                                                                }}
                                                                onInitialized={() => {
                                                                    setSelectBoxValue(entityStructure[0].queryFormProperties[0].defaultSearchField)
                                                                }}
                                                            />
                                                        </>) : (
                                                        <>
                                                            <SelectBox
                                                                colSpan={1}
                                                                isRequired={true}
                                                                searchEnabled={true}
                                                                dataSource={entityStructure[0].queryFormProperties[0].searchFields}
                                                                displayExpr={"fieldCaption"}
                                                                valueExpr={"fieldName"}
                                                                defaultValue={entityStructure[0].queryFormProperties[0].searchFields && entityStructure[0].queryFormProperties[0].searchFields[0].fieldName}
                                                                onValueChanged={(sender) => {
                                                                    setSelectBoxValue(sender.value)
                                                                }}
                                                                onInitialized={() => {
                                                                    setSelectBoxValue(entityStructure[0].queryFormProperties[0].searchFields && entityStructure[0].queryFormProperties[0].searchFields[0].fieldName)
                                                                }}
                                                            />
                                                        </>)}
                                                </SimpleItem>
                                            </GroupItem>
                                            <GroupItem colCount={6}>
                                                <SimpleItem
                                                    colSpan={3}
                                                    height="150%"
                                                    editorType="dxTextBox"
                                                    editorOptions={{
                                                        placeholder: "Preencha a expressão de pesquisa",
                                                        // onValueChanged: (getValueInput) => {
                                                        //     let objectSelectedBoxValue = entityStructure[0].queryFormProperties[0].fieldsDefs.find((e) => {if(e.fieldName === selectBoxValue){
                                                        //         return e
                                                        //     }})
                                                        //     console.log("entrou")
                                                        //     console.log(selectBoxValue)

                                                        //     if(objectSelectedBoxValue?.rules?.find((e) => {return e.type === "NumericRule"})){
                                                        //         setInput(getValueInput.value.replace(/\D/g, ''))
                                                        //     }
                                                        //     else{
                                                        //         setInput(getValueInput.value)

                                                        //     }
                                                        //     // else if(selectBoxValue.rule === "EmailRule"){
                                                        //     //     //permitindo apenas alfanumerico e espaços
                                                        //     //     // setInput(getValueInput.value.replace(/[^a-z0-9 ]/gi, ""))
                                                        //     //     setInput(getValueInput.
                                                        //     // }

                                                        // },
                                                        onInput: (getValueInput) => {
                                                            setInput(getValueInput.event.target.value)
                                                            // else if(selectBoxValue.rule === "EmailRule"){
                                                            //     //permitindo apenas alfanumerico e espaços
                                                            //     // setInput(getValueInput.value.replace(/[^a-z0-9 ]/gi, ""))
                                                            //     setInput(getValueInput.
                                                            // }

                                                        }
                                                    }
                                                    }
                                                    isRequired={true}
                                                />
                                                <ButtonItem
                                                    buttonOptions={{
                                                        icon: 'find'
                                                        , text: 'Pesquisar'
                                                        , type: 'default'
                                                        , stylingMode: 'default'
                                                        // refreshButtonOptions: false,
                                                        , useSubmitBehavior: true
                                                    }}
                                                />
                                            </GroupItem>
                                        </GroupItem>
                                    </Form>
                                </form>

                                <div className="grid-div" style={{ marginTop: '10px', height: '100%', marginBottom: "4.2em" }}>
                                    {entityStructure ? (
                                        <TabPanel
                                        >
                                            <Item
                                                title={"Resultado da consulta"}
                                                key={1}
                                            >
                                                <div style={entityStructure[0].queryFormProperties[0].showGroupPanel === 1 ? {marginBottom: "5em", marginLeft: "0.5em"} : {width: "10%", marginLeft: "0.5em", position: "relative", zIndex: "2"}}>
                                                    {entityStructure[0].grideditenabled === 1 &&
                                                        // <div className="buttons-tabpanel">
                                                            <Button
                                                                icon="rowproperties"
                                                                hint="Editar registros em lote no grid"
                                                                type="normal"
                                                                // disabled={entityStructure[0].grideditenabled === 1 ? false : true}
                                                                onClick={() => {
                                                                    setModeEditDataGrid(!modeEditDataGrid)
                                                                }}
                                                            />
                                                        // </div>
                                                    }
                                                </div>
                                                <DataGrid
                                                    className="datagrid-queryform"
                                                    style={{paddingTop: "0.5em"}}
                                                    keyExpr="id"
                                                    onEditorPreparing={onEditorPreparing}
                                                    onContextMenuPreparing={openMenuContextFunction}
                                                    ref={dataGridRef}
                                                    onSelectionChanged={selectedItemFunction}
                                                    // onRowClick={selectedItemFunction}
                                                    onRowDblClick={onRowDblClickRecordFormOpenFunction}
                                                    dataSource={masterEntity ? masterEntity : []}
                                                    rowAlternationEnabled={true}
                                                    // focusedRowEnabled={true}
                                                    // focusedRowKey={0}
                                                    repaintChangesOnly={false}
                                                    // renderAsync={true}
                                                    showBorders={true}
                                                    columnAutoWidth={true}
                                                    allowColumnResizing={false}
                                                    allowColumnReordering={true}
                                                    columnMinWidth={50}
                                                    columnResizingMode={"widget"}
                                                    columnHidingEnabled={false}
                                                    cacheEnabled={false}
                                                    // dateSerializationFormat="yyyy-MM-dd"
                                                    // customizeColumns={(cols) => {
                                                    //     // console.log(cols)
                                                    //     // if(dataGridRef?.current?.instance){
                                                    //     //     let items = dataGridRef.current.instance.getDataSource().items()
                                                    //     //     if(items.length > 0){
                                                    //     //         cols.forEach((col)=> {
                                                    //     //             for(let c = 0; c <= items.length - 1; c++){
                                                    //     //                 const itemStructorAtual = entityStructure[0].queryFormProperties[0].fieldsDefs.find((e) => {return e.fieldName === col.dataField})
                                                    //     //                 if(itemStructorAtual?.fieldMask){
                                                    //     //                     console.log(col)
                                                    //     //                     col.format = formatFunction(itemStructorAtual.fieldMask, "column", itemStructorAtual.fieldName, items[c][col.dataField])
                                                    //     //                 }
                                                    //     //             }
                                                    //     //         })
                                                    //     //     }
                                                    //     // }
                                                    //     // let items = dataGridRef.current.instance.getDataSource().items()
                                                    //     //     if(items.length > 0){
                                                    //     //         cols.forEach((col)=> {
                                                    //     //             console.log(col?.editorOptions)
                                                    //     //             col.editorOptions.mask = "000.000.000-00"
                                                    //     //         })
                                                    //     //     }

                                                    // }}
                                                    dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                                                    onRowRemoved={(sender) => {
                                                        recordDeleteJsonDetail(props.entityName, sender.data.id)
                                                        setModeEditDataGrid(false);
                                                    }}
                                                    onRowUpdating={(e) => {
                                                        setDataEntityEditedFields([...dataEntityEditedFields,
                                                            e.newData
                                                        ])
                                                    }}
                                                    onRowUpdated={(sender) => {
                                                        dataEntityEditedFields[dataEntityEditedFieldsCount]["id"] = sender.data.id
                                                        setDataEntityEditedFieldsCount(dataEntityEditedFieldsCount + 1)

                                                        console.log(dataEntityEditedFields[dataEntityEditedFieldsCount])
                                                        
                                                        if(dataEntityEditedFields?.length - 1 === dataEntityEditedFieldsCount){
                                                            for(let c = 0; c <= dataEntityEditedFields.length - 1; c++){
                                                                recordUpdateJsonDetail(props.entityName, dataEntityEditedFields[c])
                                                            }
                                                            setDataEntityEditedFields([])
                                                            setDataEntityEditedFieldsCount(0)
                                                            setModeEditDataGrid(false);
                                                        }
                                                    }}
                                                    onRowInserted={(sender) => {
                                                        const json = sender.data
                                                        recordInsertJsonDetail(props.entityName, json)
                                                        setModeEditDataGrid(false);
                                                    }}
                                                    onCellPrepared={(e) => {
                                                        changeDataRowColor(e, entityStructure[0].queryFormProperties[0].rowColor)
                                                    }}
                                                >
                                                    <HeaderFilter
                                                        allowSearch={true}
                                                        visible={true}
                                                        searchMode='contains'
                                                    />

                                                    {/* <ColumnChooser
                                                    enabled={true}
                                                /> */}

                                                    <Selection
                                                        keyExpr="id"
                                                        mode="multiple"
                                                    />
                                                    <MasterDetail
                                                        visible={true}
                                                        component
                                                    />
                                                    <Scrolling
                                                        rowRenderingMode='infinity'
                                                        showScrollbar='always'
                                                        useNative={true}
                                                    >
                                                    </Scrolling>

                                                    <Editing
                                                        allowDeleting={modeEditDataGrid}
                                                        allowUpdating={modeEditDataGrid}
                                                        allowAdding={modeEditDataGrid}
                                                        confirmDelete={true}
                                                        mode="batch"
                                                        useIcons={true}

                                                    // selectTextOnEditStart={selectTextOnEditStart}
                                                    // startEditAction={startEditAction}
                                                    />

                                                    <Paging defaultPageSize={10} />

                                                    <Sorting
                                                        mode="multiple"
                                                    />
                                                    <GroupPanel
                                                        visible={(entityStructure[0].queryFormProperties[0].showGroupPanel === 1)}
                                                        allowColumnDragging={true}
                                                    />
                                                    <Pager
                                                        visible={true}
                                                        allowedPageSizes={[10, 25, 50, 100]}
                                                        showPageSizeSelector={true}
                                                        showInfo={true}
                                                        showNavigationButtons={true}
                                                    />

                                                    <Sorting
                                                        mode="multiple"
                                                    />
                                                    {entityStructure[0].queryFormProperties[0].fieldsDefs && entityStructure[0].queryFormProperties[0].fieldsDefs.map(
                                                        (sender, index) => {
                                                            if (sender.editorType === "dxLookup" ||
                                                                sender.editorType === "dxSelectBox") {
                                                                if (sender.lookUpKeyField != null && dataLookup) {

                                                                    console.log(sender.fieldCaption)
                                                                    const dataSourceFilter = []
                                                                    const arrayValues = []
                                                                    
                                                                    if(masterEntity?.length){

                                                                        for(let i = 0; i <= masterEntity.length - 1; i++){
                                                                            if(!arrayValues?.find((e) => e === masterEntity[i][sender.fieldName])){
                                                                                arrayValues.push(masterEntity[i][sender.fieldName])
                                                                            }
                                                                        }
    
                                                                        if(dataLookup[sender.lookUpListSource]?.length){
                                                                            for(let c = 0; c <= dataLookup[sender.lookUpListSource].length - 1; c++){
                                                                                if(arrayValues?.find((e) => e === dataLookup[sender.lookUpListSource][c][`${sender.lookUpKeyField}`])){
                                                                                    dataSourceFilter.push({
                                                                                        text: dataLookup[sender.lookUpListSource][c][`${sender.lookUpResultField}`], 
                                                                                        value: dataLookup[sender.lookUpListSource][c][`${sender.lookUpKeyField}`], 
                                                                                    })
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    return (
                                                                        <Column
                                                                            key={index}
                                                                            fixed={sender.fixed}
                                                                            visible={sender.gridVisible === 1}
                                                                            groupIndex={sender.groupIndex}
                                                                            defaultSortOrder={sender.sortOrder}
                                                                            defaultSortIndex={sender.sortIndex}
                                                                            showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                            dataField={sender.fieldName}
                                                                            allowEditing={sender.readOnly != 1}
                                                                            caption={sender.fieldCaption}
                                                                            dataType={sender.fieldType}
                                                                            editorOptions={{
                                                                                showClearButton: true  
                                                                            }}
                                                                        // editCellRender={
                                                                        // format={sender.fieldMask}
                                                                        //width={sender.FieldSize}

                                                                        // visible={sender.gridVisible === 1}
                                                                        // showInColumnChooser={sender.visibleForCustomization===1}
                                                                        // dataField={sender.fieldName}
                                                                        // groupIndex={sender.groupIndex}
                                                                        // sortOrder={sender.sortOrder}
                                                                        // allowEditing={(sender.readOnly != 1)}
                                                                        // caption={sender.fieldCaption}
                                                                        // dataType={sender.fieldType}
                                                                        // format={sender.fieldMask}
                                                                        >
                                                                            <HeaderFilter
                                                                                allowSearch={true}
                                                                                visible={true}
                                                                                searchMode='contains'
                                                                                dataSource={dataSourceFilter}
                                                                                key={sender.lookUpKeyField}
                                                                            />
                                                                            <Lookup
                                                                                dataSource={dataLookup[sender.lookUpListSource]}
                                                                                valueExpr={sender.lookUpKeyField}
                                                                                displayExpr={sender.lookUpResultField}
                                                                                allowClearing={true}
                                                                            />

                                                                        </Column>
                                                                    )
                                                                }
                                                            }


                                                            else if (sender.editorType === "dxCheckBox") {
                                                                checkBoxFieldNames.push(sender.fieldName);
                                                                return (
                                                                    <Column
                                                                        key={index}
                                                                        fixed={sender.fixed}
                                                                        visible={sender.gridVisible === 1}
                                                                        groupIndex={sender.groupIndex}
                                                                        defaultSortOrder={sender.sortOrder}
                                                                        defaultSortIndex={sender.sortIndex}
                                                                        showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                        dataField={sender.fieldName}
                                                                        allowEditing={sender.readOnly != 1}
                                                                        caption={sender.fieldCaption}
                                                                        dataType={"boolean"}
                                                                    // editorOptions={editorOptionsColumns}
                                                                    // calculateCellValue={calculateCellValue}
                                                                    >

                                                                    </Column>
                                                                )
                                                            }

                                                            else if ((sender.editorType === "dxDateBox" || sender.editorType === "dxCalendar") && sender.fieldType === "datetime") {
                                                                return (
                                                                    <Column
                                                                        key={index}
                                                                        fixed={sender.fixed}
                                                                        visible={sender.gridVisible === 1}
                                                                        groupIndex={sender.groupIndex}
                                                                        defaultSortOrder={sender.sortOrder}
                                                                        defaultSortIndex={sender.sortIndex}
                                                                        showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                        dataField={sender.fieldName}
                                                                        allowEditing={sender.readOnly !== 1}
                                                                        caption={sender.fieldCaption}
                                                                        dataType={sender.fieldType}
                                                                        format="shortDateShortTime"
                                                                    />
                                                                    // <UpfForeignColumn
                                                                    //     sender={sender}
                                                                    // />
                                                                )
                                                            }
                                                            else if (sender.editorType === "upfTime") {
                                                                return (
                                                                    <Column
                                                                        key={index}
                                                                        fixed={sender.fixed}
                                                                        visible={sender.gridVisible === 1}
                                                                        groupIndex={sender.groupIndex}
                                                                        defaultSortOrder={sender.sortOrder}
                                                                        defaultSortIndex={sender.sortIndex}
                                                                        showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                        dataField={sender.fieldName}
                                                                        allowEditing={sender.readOnly !== 1}
                                                                        caption={sender.fieldCaption}
                                                                        dataType={"datetime"}
                                                                        format="shortTime"
                                                                    />
                                                                    // <UpfForeignColumn
                                                                    //     sender={sender}
                                                                    // />
                                                                )
                                                            }
                                                            else if ((sender.editorType === "dxDateBox" || sender.editorType === "dxCalendar") && sender.fieldType === "date") {
                                                                return (
                                                                    <Column
                                                                        key={index}
                                                                        fixed={sender.fixed}
                                                                        visible={sender.gridVisible === 1}
                                                                        groupIndex={sender.groupIndex}
                                                                        defaultSortOrder={sender.sortOrder}
                                                                        defaultSortIndex={sender.sortIndex}
                                                                        showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                        dataField={sender.fieldName}
                                                                        allowEditing={sender.readOnly !== 1}
                                                                        caption={sender.fieldCaption}
                                                                        dataType={sender.fieldType}
                                                                        format="shortDate"
                                                                    />
                                                                    // <UpfForeignColumn
                                                                    //     sender={sender}
                                                                    // />
                                                                )
                                                            }
                                                            else if (sender.editorType === "upfHtmlViewer" || sender.editorType === 'upfHtmlBox') {
                                                                return (
                                                                    <Column
                                                                        key={index}
                                                                        fixed={sender.fixed}
                                                                        visible={sender.gridVisible === 1}
                                                                        groupIndex={sender.groupIndex}
                                                                        defaultSortOrder={sender.sortOrder}
                                                                        defaultSortIndex={sender.sortIndex}
                                                                        showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                        dataField={sender.fieldName}
                                                                        allowEditing={sender.readOnly !== 1}
                                                                        caption={sender.fieldCaption}
                                                                        dataType={sender.fieldType}
                                                                        customizeText={(cellInfo) => {
                                                                            if(cellInfo.value?.[0] === "<"){
                                                                                return cellInfo.value.replace(/(<([^>]+)>)/ig, "");
                                        
                                                                            }
                                                                            else{
                                                                                return cellInfo.value
                                                                            }
                                                                        }}
                                                                        cellRender={(cellData) => {
                                                                            return(
                                                                                <UpfHtmlEditor
                                                                                    referencia={dataGridRef?.current?.instance}
                                                                                    setFormDataJson={props.setFormDataJson}
                                                                                    setDataEntity={props.setDataEntity}
                                                                                    dataEntity={cellData.data}
                                                                                    entityStructor={sender}
                                                                                    colSpan={sender.colSpan}
                                                                                    caption={sender.fieldCaption}
                                                                                    dataField={sender.fieldName}
                                                                                    disabled={!modeEditDataGrid.edit}
                                                                                    hint={sender.hint}
                                                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                                                    showEditBar={false}
                                                                                    cellData={cellData}
                                                                                />
                                                                            )}
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                            else if(sender.editorType === "upfPassword"){
                                        
                                                                return(
                                                                    <Column 
                                                                        hint={sender.hint}
                                                                        helpText={sender.hintDocked ? sender.hint : null}
                                                                        // colSpan={colSpanFieldsFunction(sender.colSpan, props.colCount)} 
                                                                        caption={sender.fieldCaption} 
                                                                        dataField={sender.fieldName} 
                                                                        dataSource={props.dataEntity}
                                                                        key={index}
                                                                        fixed={sender.fixed}
                                                                        visible={sender.gridVisible === 1}
                                                                        groupIndex={sender.groupIndex}
                                                                        defaultSortOrder={sender.sortOrder}
                                                                        defaultSortIndex={sender.sortIndex}
                                                                        showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                        allowEditing={sender.readOnly != 1}
                                                                        dataType={sender.fieldType} 
                                                                        editorOptions={{mode: "password"}}
                                                                        cellRender={(cellData) => {
                                                                            return(
                                                                                <UpfPasswordComponent 
                                                                                    defaultValue={cellData.value}
                                                                                    // colSpan={colSpanFieldsFunction(sender.colSpan, props.colCount)}
                                                                                    modeEditOn={props.modeEditOn}
                                                                                    entityStructure={sender}
                                                                                    setModeEditForm={props.setModeEditForm}
                                                                                    setFormDataJson={props.setFormDataJson}
                                                                                    setDataEntity={props.setDataEntity}
                                                                                    // dataEntity={props.dataEntity}
                                                                                    modeEditForm={props.modeEditForm}
                                                                                    isGrid={true}
                                                                                    referencia={dataGridRef?.current?.instance}
                                                                                    dataEntity={cellData.data?.[sender.fieldName] ? cellData.data?.[sender.fieldName] : cellData.data}
                                                                                    colSpan={sender.colSpan} 
                                                                                    caption={sender.fieldCaption} 
                                                                                    dataField={sender.fieldName}
                                                                                    disabled={!modeEditDataGrid.edit} 
                                                                                    hint={sender.hint}
                                                                                    setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                                                    showEditBar={false}
                                                                                    cellData={cellData}
                                                                                />
                                                                            )}}
                                                                    >
                                                                        
                                                                    
                                                                    </Column>
                                                                )
                                                            }
                                                            // else if (sender.editorType === "dxColorBox") {
                                                            //         return(
                                                            //             <Column
                                                            //             visible={sender.gridVisible === 1}
                                                            //             showInColumnChooser={sender.visibleForCustomization===1}
                                                            //             key={index}
                                                            // fixed={sender.fixed}
                                                            //             dataField={sender.fieldName}
                                                            //             groupIndex={sender.groupIndex}
                                                            //             sortOrder={sender.sortOrder}
                                                            //             allowEditing={(sender.readOnly != 1)}
                                                            //             caption={sender.fieldCaption}
                                                            //             dataType={sender.fieldType}
                                                            //             format={sender.fieldMask}
                                                            //                 >

                                                            //             </Column>
                                                            //                     )
                                                            // }

                                                            else {
                                                                return (
                                                                    <Column
                                                                        key={index}
                                                                        fixed={sender.fixed}
                                                                        visible={sender.gridVisible === 1}
                                                                        showInColumnChooser={sender.visibleForCustomization === 1}
                                                                        dataField={sender.fieldName}
                                                                        groupIndex={sender.groupIndex}
                                                                        sortOrder={sender.sortOrder}
                                                                        allowEditing={(sender.readOnly != 1)}
                                                                        caption={sender.fieldCaption}
                                                                        dataType={sender.fieldType}
                                                                        format={formatFunction(sender.fieldMask, "column", sender.fieldName, masterEntity, false)}
                                                                    />
                                                                );
                                                            }
                                                            // }

                                                        }
                                                    )
                                                    }
                                                    <Summary 
                                                        calculateCustomSummary={calculateCustomSummary}
                                                    >
                                                        <TotalItem
                                                            column={dataGridRef?.current?.instance?.getVisibleColumns()[1]?.dataField}
                                                            summaryType="count"
                                                            customizeText={(data) => `Qtd: ${data.value}`}
                                                        />
                                                        <TotalItem
                                                            name={`{"tipo": "count", "columnName": "${dataGridRef?.current?.instance?.getVisibleColumns()[1]?.dataField}"}`}
                                                            summaryType="custom"
                                                            displayFormat="Sel.: {0}"
                                                            showInColumn={dataGridRef?.current?.instance?.getVisibleColumns()[1]?.dataField}
                                                        />
                                                     
                                                            {entityStructure[0].queryFormProperties[0]?.summary && entityStructure[0].queryFormProperties[0]?.summary?.map((item, index) => {
                                                                    if (item.fieldType === "money") {
                                                                        return (
                                                                            <TotalItem
                                                                                column={item.fieldName}
                                                                                summaryType={item.summarytype}
                                                                                fieldType={item.fieldType}
                                                                                showInGroupFooter={false}
                                                                                alignByColumn={true}
                                                                                rowRenderingMode="standard"
                                                                                format={{
                                                                                    style: 'currency',
                                                                                    currency: 'BRL',
                                                                                    precision: 2,
                                                                                }}
                                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                                            />
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <TotalItem
                                                                                column={item.fieldName}
                                                                                summaryType={item.summarytype}
                                                                                fieldType={item.fieldType}
                                                                                showInGroupFooter={false}
                                                                                alignByColumn={true}
                                                                                rowRenderingMode="standard"
                                                                                customizeText={(data) => `Total: ${data.value}`}
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                            }

                                                            {entityStructure[0].queryFormProperties[0]?.summary && entityStructure[0].queryFormProperties[0]?.summary?.map((item, index) => {
                                                                    if (item.fieldType === "money") {
                                                                        return (
                                                                            <TotalItem
                                                                                name={`{"tipo": "value", "columnName": "${item.fieldName}"}`}
                                                                                summaryType="custom"
                                                                                valueFormat={{
                                                                                    style: 'currency',
                                                                                    currency: 'BRL',
                                                                                    precision: 2,
                                                                                }}
                                                                                displayFormat="Sel.: {0}"
                                                                                showInColumn={item.fieldName}
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                    </Summary>
                                                </DataGrid>
                                            </Item>
                                        </TabPanel>

                                    ) : null
                                    }
                                </div>
                            </div>
                            {/* </div> */}
                        </>) : (<Loading />
                        )}
                </div>
            </ScrollView>
        </div>
    );
}
export default memo(QueryForm);
