import MultiView from 'devextreme-react/multi-view';
import { useState, useEffect } from "react"
import ScrollView from 'devextreme-react/scroll-view';
import { useRequestsApi } from '../../../Context/RequestsApi';
import { httpRequest } from '../../../functions';
import { applicationID } from '../../../api/ApplicationID';
import { convertDate } from '../../../functions/convertDate';
import Passo1 from "./EtapasAcordo/Passo1"
import Passo2 from "./EtapasAcordo/Passo2"
import Passo3 from "./EtapasAcordo/Passo3"
import ConsultaBoletoForm from '../../ConsultaBoletoForm';
import Loading from '../../../CardComponents/Loading';
import { convertKeysToLowerCase } from '../../../functions/convertKeysToLowerCase';
import { convertFieldNameToLowerCase } from '../../../functions/convertFieldNameToLowerCase';
import "./style.css"

const GerarAcordoForm = (props) => {
    const {requestsApi} = useRequestsApi()

    console.log(props.habilitarGerarAcordo)

    const contentTab = (sender) =>{
        return(
            <>
                {sender.component}
            </>
        )
    }

    const loadingPanelOptions = {
        visible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        closeOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)
    const [dataLookupState, setDataLookupState] = useState([])

    const [disabledButtonState, setDisabledButtonState] = useState(true)

    const [dadosPagementoAcordoObjectState, setDadosPagementoAcordoObjectState] = useState()

    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hideLoadPanel = () => {
        setDisabledButtonState(false)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }

    const showLoadPanel =() => {
        setDisabledButtonState(true)
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }


    const entityStructureFieldsDefs = [ 
        {
            fieldname: "_parcela",
            fieldcaption: "Parcela",
            editortype: null,
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },

        {
            fieldname: "_tipoParcela",
            fieldcaption: "Tipo parcela",
            editortype: null,
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },

        {
            fieldname: "_numerodocumento",
            fieldcaption: "Número Documento",
            editortype: null,
            format: null,
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_vencimento",
            fieldcaption: "Vencimento",
            editortype: "dxDateBox",
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_moravaloratualizacao",
            fieldcaption: "Mora Valor Atualização",
            editortype: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_moravalorparcial",
            fieldcaption: "Mora Valor Parcial",
            editortype: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_moravalorquitacao",
            fieldcaption: "Mora Valor Quitação",
            editortype: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },

        {
            fieldname: "_valor",
            fieldcaption: "Principal",
            editortype: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_fasemorataxaatualizacao",
            fieldcaption: "Taxa Mora Atualização",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseMoraTaxaParcial",
            fieldcaption: "Taxa Mora Parcial",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseMoraTaxaQuitacao",
            fieldcaption: "Taxa Mora Quitação",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_fasetaxadescricaoatualizacao",
            fieldcaption: "Taxa Descrição Atualização",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseJurosTaxaAtualizacao",
            fieldcaption: "Juros Taxa Atualização",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseMultaTaxaAtualizacao",
            fieldcaption: "Multa Taxa Atualização",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseHonorarioTaxaAtualizacao",
            fieldcaption: "Honorário Taxa Atualização",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_fasetaxadescricaoquitacao",
            fieldcaption: "Taxa Descricao Quitação",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseJurosTaxaQuitacao",
            fieldcaption: "Juros Taxa Quitação",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseMultaTaxaQuitacao",
            fieldcaption: "Multa Taxa Quitação",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_faseHonorarioTaxaQuitacao",
            fieldcaption: "Honorário Taxa Quitação",
            editortype: null,
            format: "percent",
            visible: 0,
            showincolumnchooser: 1
        },
        {
            fieldname: "_jurosvalorquitacao",
            fieldcaption: "Juros Quitação",
            editortype: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_multavalorquitacao",
            fieldcaption: "Multa Quitação",
            editortype: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_JurosValorAtualizacao",
            fieldcaption: "Juros Atualização",
            editortype: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_MultaValorAtualizacao",
            fieldcaption: "Multa Atualização",
            editortype: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_HonorarioSobrePrincipal",
            fieldcaption: "Honorário sobre principal",
            editortype: "dxCheckBox",
            format: null,
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_HonorarioSobreParcelasVincendas",
            fieldcaption: "Honorário Parcelas Vincendas",
            editortype: "dxCheckBox",
            format: null,
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_HonorarioValorAtualizacao",
            fieldcaption: "Honorário Atualização",
            editortype: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_honorariovalorquitacao",
            fieldcaption: "Honorário Quitação",
            editortype: null,
            format: 'money',
            visible: 0,
            showincolumnchooser: 1
        },

        {
            fieldname: "_diasatraso",
            fieldcaption: "Atraso",
            editortype: null,
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },

        {
            fieldname: "_totalvalorquitacao",
            fieldcaption: "Valor a pagar (quitação)",
            editortype: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },

        {
            fieldname: "_totalvaloratualizacao",
            fieldcaption: "Valor a pagar (atualização)",
            editortype: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_ipcavalorquitacao",
            fieldcaption: "Valor IPCA (quitação)",
            editortype: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_ipcavaloratualizacao",
            fieldcaption: "Valor IPCA (atualização)",
            editortype: null,
            format: 'money',
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_numeroAcordo",
            fieldcaption: "Número acordo",
            editortype: null,
            format: null,
            visible: 1,
            showincolumnchooser: 1
        },
        {
            fieldname: "_isacordo",
            fieldcaption: "Acordo",
            editortype: 'dxCheckBox',
            format: null,
            visible: 1,
            showincolumnchooser: 1
        }

    ]

    const [entityStructureFieldsDefsState, setEntityStructureFieldsDefsState] = useState()


    const getParcelasVencidasVincendasFunction = (dataSelecionada, customFormVisibleAcordo) => {
        setArraySelecionadosState([])
        showLoadPanel()
        let stringDate = new Date().toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
        let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')

        //junta o formato da data da segunda linha com a hora da primeira
        let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
        httpRequest('POST', requestsApi.getParcelasVencidasVincendas, requestsApi.basicAuth, {
            // entityName: "_titulo_parcela_vencidas_vincendas",
            // fieldname: "_tituloid",
            tituloId: props.tituloId,//proximaFichaDados.tituloId,
            applicationId: applicationID,
            parcelas: props.parcelasIdArray,
            tipoNegociacao: 2, //1 BOLETO (form de boleto)/ 2 ACORDO (form de negociacao),
            dataCalculo: dataSelecionada ? dataSelecionada : stringDateResult
        })
        .then((sender) => {
            if(sender === "<HTML><BODY><B>200 OK</B></BODY></HTML>"){
                setMessage("Valor de getParcelasVencidasVincendas inválido!")
                setVisible(true)
            }
            else{
                const resultSenderJson = JSON.parse(sender)
                const resultSenderJsonToLowerCase = convertKeysToLowerCase(resultSenderJson)
                convertFieldNameToLowerCase(resultSenderJsonToLowerCase)

                const estruturaCampos = customFormVisibleAcordo ? customFormVisibleAcordo : entityStructureFieldsDefsState
                const estruturaCamposToLowerCase = convertKeysToLowerCase(estruturaCampos)
                convertFieldNameToLowerCase(estruturaCamposToLowerCase)


                //pega os campos checkbox e altera de null pra 0
                for (let c = 0; c <= resultSenderJsonToLowerCase.length - 1; c++) {
                    resultSenderJsonToLowerCase[c]["_diasatraso"] = resultSenderJsonToLowerCase[c]?._diasatrasocalculado
                    for (let i = 0; i <= senderFieldNamesCheckBox.length - 1; i++) {
                        if (resultSenderJsonToLowerCase[c][`${senderFieldNamesCheckBox[i]}`] === null) {
                            resultSenderJsonToLowerCase[c][`${senderFieldNamesCheckBox[i]}`] = 0
                        }
                    }
                }

                resultSenderJsonToLowerCase.sort((a, b) => { return b?._diasatraso - a?._diasatraso})

                let TotalValorAtualizacao = 0;
                let TotalValorQuitacao = 0;
                let TotalValor = 0;
                resultSenderJsonToLowerCase.map(val => {
                    TotalValorAtualizacao += val._totalvaloratualizacao
                    TotalValorQuitacao += val._totalvalorquitacao
                    TotalValor += val._valor
                })
                // setTotals({ _totalvaloratualizacao: TotalValorAtualizacao, _totalvalorquitacao: TotalValorQuitacao, _valor: TotalValor })
                
                setDataSourceParcelasVencidasVincendasState(resultSenderJsonToLowerCase)
                

                // props.setDataSourceParcelasVencidasVincendasState(resultSenderJsonToLowerCase)



                //pega os campos do tipo data e converte para não pegar o fuso horario do browser
                convertDate(resultSenderJsonToLowerCase, estruturaCamposToLowerCase)
                //

                hideLoadPanel()
            }
            
        })
        .catch((error) => {
            console.log(error)
            setMessage(error.data.error)
            setVisible(true)
        })
    }

    const getColumnsDataGridParcelas = () => {
        showLoadPanel()

        httpRequest('POST', requestsApi.getEntityFields, requestsApi.basicAuth, {
            applicationId: applicationID,
            entityName: "_titulo_parcela"
        })
        .then((sender) => {
            const resultSenderJson = JSON.parse(sender)
            const customFormVisibleAcordo = []

            for(let c = 0; c <= resultSenderJson.length - 1; c++){
                if(resultSenderJson[c]?.customformvisible?.length > 0){
                    const customFormVisibleArray = resultSenderJson[c]?.customformvisible.toLowerCase().split(";")
                    if(customFormVisibleArray.find((e) => e === "acordo")){
                        resultSenderJson[c]["visible"] = 1
                        resultSenderJson[c]["showincolumnchooser"] = 1
                        resultSenderJson[c]["format"] = resultSenderJson[c]?.fieldtype
                        customFormVisibleAcordo.push(resultSenderJson[c])
                    }
                }
            }

            let stringDate = new Date().toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
            let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')

            //junta o formato da data da segunda linha com a hora da primeira
            let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
                

            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                applicationId: applicationID,
                entityName: "_titulo_parcela"
            })
            .then((sender) => {
                const resultSenderJson = JSON.parse(sender)?.[0]?.queryFormProperties?.[0]?.fieldsDefs
                if (JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0) {
                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                        "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                    })
                        .then((sender) => {
                            const senderDataLookup = JSON.parse(sender)

                            setDataLookupState(senderDataLookup)

                        })
                        .catch((error) => console.log(error))
                }

                const resultSenderJsonToLowerCase = convertKeysToLowerCase(resultSenderJson)
                convertFieldNameToLowerCase(resultSenderJsonToLowerCase)

                const customFormVisibleAcordoToLowerCase = convertKeysToLowerCase(customFormVisibleAcordo)
                convertFieldNameToLowerCase(customFormVisibleAcordoToLowerCase)

                const arrayFinal = []
                if(customFormVisibleAcordo?.length > 0){
                    const colunaDiasAtraso = resultSenderJsonToLowerCase.find((e) => e.fieldname === "_diasatraso")
                    if(colunaDiasAtraso){
                        colunaDiasAtraso["visible"] = 1
                        colunaDiasAtraso["showincolumnchooser"] = 1
                        colunaDiasAtraso["format"] = colunaDiasAtraso?.fieldtype
                        customFormVisibleAcordoToLowerCase.push(colunaDiasAtraso)
                    }
                    for(let c = 0; c <= resultSenderJsonToLowerCase.length - 1; c++){
                        const objcustomFormVisibleAcordo = customFormVisibleAcordoToLowerCase.find((e) => e.fieldname === resultSenderJsonToLowerCase[c].fieldname)
                        if(objcustomFormVisibleAcordo){
                            arrayFinal.push({...resultSenderJsonToLowerCase[c], ...objcustomFormVisibleAcordo})
                        }
                    }


                    setEntityStructureFieldsDefsState(arrayFinal)
                    getParcelasVencidasVincendasFunction(stringDateResult, arrayFinal)
                }
                else{

                    const entityStructureFieldsDefsToLowerCase = convertKeysToLowerCase(entityStructureFieldsDefs)
                    convertFieldNameToLowerCase(entityStructureFieldsDefsToLowerCase)

                    // for(let c = 0; c <= resultSenderJsonToLowerCase.length - 1; c++){
                    //     const objEntityStructureFieldsDefsState = entityStructureFieldsDefsToLowerCase.find((e) => e?.fieldname === resultSenderJsonToLowerCase[c]?.fieldname)
                        
                    //     if(objEntityStructureFieldsDefsState){
                    //         arrayFinal.push({...resultSenderJsonToLowerCase[c], ...objEntityStructureFieldsDefsState})
                    //     }
                    // }
    
                    // console.log(arrayFinal)
                    // console.log(resultSenderJsonToLowerCase)
                    // console.log(entityStructureFieldsDefsToLowerCase)

                    setEntityStructureFieldsDefsState(entityStructureFieldsDefsToLowerCase)
                    getParcelasVencidasVincendasFunction(stringDateResult, entityStructureFieldsDefsToLowerCase)
                }

                hideLoadPanel()
            })
            .catch((error) => {
                hideLoadPanel()
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
        })
        .catch((error) => {
            hideLoadPanel()
            console.log(error)
            setMessage(error.data.error)
            setVisible(true)
        })
    }

    const senderFieldNamesCheckBox = ["_isacordo"]

    const [dataSourceParcelasVencidasVincendasState, setDataSourceParcelasVencidasVincendasState] = useState([])


    const [dateBoxState, setDateBoxState] = useState(null)
    const [arraySelecionadosState, setArraySelecionadosState] = useState([])

    const [dataLookup, setDataLookup] = useState()

    useEffect(() => {
        getColumnsDataGridParcelas()
        if(!dataLookup){
            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                "lookUpEntities": [
                    {
                        "lookUpEntity": "TipoNegociacao"
                    },
                    {
                        "lookUpEntity": "OpcaoNegociacao"
                    },
                    {
                        "lookUpEntity": "_tipo_contato"
                    },
                    {
                        "lookUpEntity": "_pontuacao_contato"
                    }
                ]
            })
                .then((sender) => {
                    const resultJson = JSON.parse(sender)
                    setDataLookup(resultJson)
                })
                .catch((error) => {
                    console.log(error)
                    setMessage(error.data.error)
                    setVisible(true)
                })
        }
    }, [])
   
    const [boletoBinarioId, setBoletoBinarioId] = useState()
    const [indexMultiViewItemsGerarAcordo, setIndexMultiViewItemsGerarAcordo] = useState(0);
    const [gerarAcordoDataSource, setGerarAcordoDataSource] = useState();
    const [acordoDataGridDataSource, setAcordoDataGridDataSource] = useState();
    const [jsonRequest, setJsonRequest] = useState({});  
    const [consultaBoletoFormVisibleState, setConsultaBoletoFormVisibleState] = useState(false)

    
    
    const getEmailTelefonesBoleto = (boletoPdf, boletoId) => {
        //para pegar o email do cliente
        httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
            "applicationId": applicationID,
            "entityName": "_cadastro",
            "fieldName": "id",
            "expressao": props.clienteId
        })
        .then((sender) => {
            const resultSenderJsonCliente = JSON.parse(sender) 
            // props.setGerarAcordoDataSource({...props.gerarAcordoDataSource, email : resultSenderJsonCliente.records[0].cryptemail})
            
            //para pegar os telefones
            httpRequest('POST', requestsApi.getEntityRecords, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "entityName": "_cadastro_contatos",
                "fieldName": "_cadastro_id",
                "expressao": props.clienteId
            })
            .then((sender) => {
                const resultSenderJsonTelefones = JSON.parse(sender)
                setGerarAcordoDataSource({...gerarAcordoDataSource, 
                    telefones : resultSenderJsonTelefones.records,
                    boletoPdf: boletoPdf,
                    boletoId: boletoId,
                    email : resultSenderJsonCliente.records[0]._cryptemail
                })
                setConsultaBoletoFormVisibleState(true)
                setIndexMultiViewItemsGerarAcordo(1)
            })
            .catch((err) => {
                setGerarAcordoDataSource({...gerarAcordoDataSource, 
                    boletoPdf: boletoPdf,
                    boletoId: boletoId,
                    email : resultSenderJsonCliente.records[0]._cryptemail
                })
                setConsultaBoletoFormVisibleState(true)
                setIndexMultiViewItemsGerarAcordo(1)})

        })
        .catch((err) => {console.log(err)}) 
    }

    const multiViewItems = [
        {
            //  title: itemClicado.itemData.text
            // ,icon: itemClicado.itemData.icon
            className: "tab-sheet"
            //,icon: '/images/favicon.ico'
            ,component:
                <ScrollView  width='100%' height='100%'>
                    <Passo1
                        setJsonRequest={setJsonRequest} 
                        tituloId={props.tituloId}
                        userType={"operador"}
                        showTitle={props.showTitle}
                        indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                        setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                        gerarAcordoDataSource={gerarAcordoDataSource}
                        setGerarAcordoDataSource={setGerarAcordoDataSource}
                        hideUpfPopupForm={props.hideUpfPopupForm}
                        dataLookupState={dataLookupState}
                        dateBoxState={dateBoxState}
                        setDateBoxState={setDateBoxState}
                        entityStructureFieldsDefs={entityStructureFieldsDefsState}
                        getParcelasVencidasVincendasFunction={getParcelasVencidasVincendasFunction}
                        dataSourceParcelasVencidasVincendasState={dataSourceParcelasVencidasVincendasState}
                        dataLookup={dataLookup}
                        loadingPanelState={loadingPanelState}
                        disabledButtonState={disabledButtonState}
                        visible={visible}
                        setVisible={setVisible}
                        message={message}
                        setMessage={setMessage}
                        arraySelecionadosState={arraySelecionadosState}
                        setArraySelecionadosState={setArraySelecionadosState}
                        marcarTodasParcelas={props.parcelasIdArray ? true : false}
                    />
                </ScrollView>
            // ,text: Math.random()
            // ,type: itemClicado.itemData.path
        },
        {
        //     title: itemClicado.itemData.text
        //    ,icon: itemClicado.itemData.icon
           className: "tab-sheet"
           //,icon: '/images/favicon.ico'
           ,component: <ScrollView  width='100%' height='100%'>
                           <Passo2
                                tituloId={props.tituloId}
                                credorId={props.credorId}
                                clienteId={props.clienteId}
                                jsonRequest={jsonRequest}
                                dataLookup={dataLookup}
                                indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                                setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                                gerarAcordoDataSource={gerarAcordoDataSource}
                                setGerarAcordoDataSource={setGerarAcordoDataSource}
                                setAcordoDataGridDataSource={setAcordoDataGridDataSource}
                                hideUpfPopupForm={props.hideUpfPopupForm}
                                // setBoletoBinarioId={setBoletoBinarioId}
                                setDadosPagementoAcordoObjectState={setDadosPagementoAcordoObjectState}
                                habilitarGerarAcordo={props.habilitarGerarAcordo}
                                getEmailTelefonesBoleto={getEmailTelefonesBoleto}
                                consultaBoletoFormVisibleState={consultaBoletoFormVisibleState}
                                setConsultaBoletoFormVisibleState={setConsultaBoletoFormVisibleState}
                           />
                       </ScrollView>
           ,text: Math.random()
        //    ,type: itemClicado.itemData.path
        },
        {
        className: "tab-sheet"
        ,component: <ScrollView  width='100%' height='100%'>
                        <Passo3 
                            tituloId={props.tituloId}
                            clienteId={props.clienteId}
                            credorId={props.credorId}
                            indexMultiViewItemsGerarAcordo={indexMultiViewItemsGerarAcordo}
                            setIndexMultiViewItemsGerarAcordo={setIndexMultiViewItemsGerarAcordo}
                            gerarAcordoDataSource={gerarAcordoDataSource}
                            acordoDataGridDataSource={acordoDataGridDataSource}
                            setGerarAcordoDataSource={setGerarAcordoDataSource}
                            hideUpfPopupForm={props.hideUpfPopupForm}
                            dadosPagementoAcordoObjectState={dadosPagementoAcordoObjectState}
                            habilitarGerarAcordo={props.habilitarGerarAcordo}
                            getEmailTelefonesBoleto={getEmailTelefonesBoleto}
                        />
                    </ScrollView>
        ,text: Math.random()
        },
        // {
        //     className: "tab-sheet"
        //     ,component: <ScrollView  width='100%' height='100%'>
        //                     <ConsultaBoletoForm 
        //                         boletoId={gerarAcordoDataSource?.boletoId}
        //                         arquivopdf={gerarAcordoDataSource?.boletoPdf}
        //                         email={gerarAcordoDataSource?.email}
        //                         telefones={gerarAcordoDataSource?.telefones}
        //                         dataLookup={dataLookup}
        //                         masterEntityId={gerarAcordoDataSource?.masterEntityId}
        //                         dataSourceBoletoState={gerarAcordoDataSource}
        //                         setIndexMultiViewItemsGerarBoleto={setIndexMultiViewItemsGerarAcordo}
        //                         indexMultiViewItemsGerarBoleto={indexMultiViewItemsGerarAcordo}
        //                     />
        //                 </ScrollView>
        //     ,text: Math.random()
        //     }
    ]




    // useEffect(() => {
    //     console.log(indexMultiViewItemsGerarAcordo)
    //     console.log(gerarAcordoDataSource)
    // }, [indexMultiViewItemsGerarAcordo, gerarAcordoDataSource])

    return (
        <div style={{height: "78vh", padding: "0.5em"}}>
            {entityStructureFieldsDefsState ? 
                (
                    <MultiView
                        items={multiViewItems} 
                        deferRendering={true}
                        itemRender={contentTab}
                        selectedIndex={indexMultiViewItemsGerarAcordo}
                        tabIndex={indexMultiViewItemsGerarAcordo}
                        repaintChangesOnly={false}
                        animationEnabled={false} 
                        swipeEnabled={false}
                        showNavButtons={true}
                        scrollByContent={true}
                        // selectedItem={multiViewItems[indexMultiViewItemsGerarAcordo]}
                    />
                ) 
                : 
                (
                    <Loading />
                )
            }
            
        </div>
    )
}

export default GerarAcordoForm;