export const convertKeysToLowerCase = (obj) => {
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(convertKeysToLowerCase);
    }

    return Object.keys(obj).reduce((acc, key) => {
        const lowerCaseKey = key.toLowerCase();
        acc[lowerCaseKey] = convertKeysToLowerCase(obj[key]);
        return acc;
    }, {});
}
