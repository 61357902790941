import {useState, useEffect, useRef, useCallback, memo} from "react"
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import Loading from "../../CardComponents/Loading"
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { applicationID, relationId } from '../../api/ApplicationID';
import RecordForm from "../RecordForm";
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Lookup,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Editing
    } from 'devextreme-react/data-grid';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import UpfPopupMessage from "../../CardComponents/UpfPopupMessage"
import RecordFormPopup from "../RecordForm/RecordFormPopup";
import { Toast } from 'devextreme-react/toast';
import notify from 'devextreme/ui/notify';
import { LoadPanel } from 'devextreme-react/load-panel';
import { validatePermission } from "../../functions/validatePermission";
import "./style.css"

const ConsultaBoletoForm = (props) => {
    console.log(props)
    const refDataGridConsultaBoletoFormTelefones = useRef(null)
    const refDataGridConsultaBoletoFormEmails = useRef(null)

    const loadingPanelOptions = {
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        closeOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)


    //Funções
    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])
    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    const [modeEditDataGridTelefonesState, setModeEditDataGridTelefonesState] = useState(false)
    const [modeEditDataGridEmailsState, setModeEditDataGridEmailsState] = useState(false)


    const {requestsApi, setRequestsApi} = useRequestsApi()
    const [contatosDataSourceState, setContatosDataSourceState] = useState([])
    const [emailsDataSourceState, setEmailsDataSourceState] = useState([])
    const [dataLookupState, setDataLookupState] = useState([])
    const [states, setStates] = useState({
        selectedItems: []
    })
    const [resultBinary, setResultBinary] = useState()
    const popups = {
        isVisible: false
    };
    const [messageError, setMessageError] = useState();
    const [popup, setPopup] = useState(popups);

    //estado e função do UpfPopup
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const hidePopup = () => {
        setVisible(false)
    }
    //---------------------------

    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState(false)
    const [desabilitarBotoesState, setDesabilitarBotoesState] = useState(true)
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState()
    const [detailEntityName, setDetailEntityName] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()
    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [dataEntity, setDataEntity] = useState()
    const [masterDetailEntity, setMasterDetailEntity] = useState()
    const [popupState, setPopupState] = useState(false)
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()
    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [stateModeEdit, setStateModeEdit] = useState(false);
    const [emailState, setEmailState] = useState([])
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'info',
        message: ""
    })
    const [entityStructureRecordFormBoleto, setEntityStructureRecordFormBoleto] = useState()


    const onHiding = () => {
        setEntityStructureRecordFormBoleto(null)

        setToastConfig({
            ...toastConfig,
            isVisible: false
        });
    }

    const toastConfiguration = useCallback((visible, type, message) => {
        setToastConfig({
            ...toastConfig,
            isVisible: visible,
            type: type,
            message: message
        });
    }, [toastConfig])

    const [dataLookupRecordFormBoleto, setDataLookupRecordFormBoleto] = useState()


    useEffect(() => {
        console.log(props)
        httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
            "applicationId": applicationID
            ,"entityName": '_cadastro_emails'
            ,"fieldName": "_cadastro_id"
            ,"expressao": props.masterEntityId
        })
        .then((sender) => {
            const resultJson = JSON.parse(sender)
            setEmailsDataSourceState(resultJson?.masterEntity?.[0]?.records)
            
        })
        .catch((error) => {
            // setPopupStates({
            //     visible: true,
            //     message: error.data.error
            // })
        })

        if(props.telefones){
            setContatosDataSourceState(props.telefones)
        }
        if(props.dataLookup){
            setDataLookupState(props.dataLookup)
        }
        // if(props.email){
        //     setEmailState(props.email)
        // }
        if((props.boletoId || props.arquivopdf) && !entityStructureRecordFormBoleto){
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "relationId": relationId,
                "entityName": "_titulo_boleto"
            })
            .then((sender) => {
                const resulGetEntityStructureJson = JSON.parse(sender)
                setEntityStructureRecordFormBoleto(resulGetEntityStructureJson)
                if (resulGetEntityStructureJson[0]["lookUpEntities"]?.length > 0) {
                    const sendLookupRequest = {
                        "lookUpEntities": resulGetEntityStructureJson[0].lookUpEntities
                    }
                    httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, sendLookupRequest)
                        .then(senderLookup => {
                            const resultLookup = JSON.parse(senderLookup)
                            setDataLookupRecordFormBoleto(resultLookup)
                        })
                        .catch((error) => {console.log(error)})
                }
            })
            .catch((error) => {console.log(error)})
        }
    },[props.consultaBoletoFormPopupState, props.email, props.telefones, props.boletoId, props.arquivopdf, entityStructureRecordFormBoleto])

    useEffect(() => {
        if(props.boletoId){
            httpRequest('POST', requestsApi.getBoletoPDF, requestsApi.basicAuth, {
                "applicationId": applicationID,
                "relationId": relationId,
                "tituloBoletoId": props.boletoId
            })
            .then((sender) => {
                const resultJson = JSON.parse(sender)
                console.log(resultJson._arquivo)
                // convertBinaryForPDF(resultJson.arquivo)
                if (resultJson._arquivo != null) {
                    var contentType = "application/pdf";
              
                    setResultBinary({
                      doc: "data:" + contentType + ";base64," + resultJson._arquivo
                    });
                    setDesabilitarBotoesState(false)
                } 
    
                else {
                    setDesabilitarBotoesState(true)
                    setResultBinary({
                      doc: ""
                    });
                }
            })
            .catch((error) => {
                setPopupStates({
                    visible: true,
                    message: error.data.error
                })
            })
        }

    }, [props.boletoId])

    const [permissoesEmailsState, setPermissoesEmailsState] = useState()
    const [permissoesContatosState, setPermissoesContatosState] = useState()

    useEffect(() => {
        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            entityName: "_cadastro_contatos",
            applicationId: applicationID,
        })
        .then((sender) => {
            const estruturaContatos = JSON.parse(sender)

            setPermissoesContatosState(validatePermission(estruturaContatos?.[0]?.masterEntity?.objectid))
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                entityName: "_cadastro_emails",
                applicationId: applicationID,
            })
            .then((sender) => {
            const estruturaEmails = JSON.parse(sender)

                setPermissoesEmailsState(validatePermission(estruturaEmails?.[0]?.masterEntity?.objectid))
            })
            .catch((error) => {
                setPopupStates({
                    visible: true,
                    message: error.data.error
                })
            })
        })
        .catch((error) => {
            setPopupStates({
                visible: true,
                message: error.data.error
            })
        })
    }, [])

    const hidePopupBoleto = useCallback(() =>{
        setPopup({
            ...popup,
            isVisible: false,
        })
    },[popup]);

    const closeButtonPopup = {
        text: 'Fechar'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };

    const openPopup = () => {
        setPopup({
            ...popup
                ,isVisible: true
        })
    }

    const convertBinaryForPDF = () => {
        if(!desabilitarBotoesState){
            if(props?.arquivopdf){
                var contentType = "application/pdf";
            
                setResultBinary({
                    doc: "data:" + contentType + ";base64," + props.arquivopdf
                });
    
                openPopup()
               
            }
            else{
                openPopup()
                // httpRequest('POST', requestsApi.getBoletoPDF, requestsApi.basicAuth, {
                //     "applicationId": applicationID,
                //     "relationId": relationId,
                //     "tituloBoletoId": props.boletoId
                // })
                // .then((sender) => {
                //     const resultJson = JSON.parse(sender)
                //     // convertBinaryForPDF(resultJson.arquivo)
                //     if (resultJson._arquivo != null) {
                //         var contentType = "application/pdf";
                  
                //         setResultBinary({
                //           doc: "data:" + contentType + ";base64," + resultJson._arquivo
                //         });
                //       } else {
                //         setResultBinary({
                //           doc: ""
                //         });
                //     }
                // })
                // .catch((error) => {
                //     setPopupStates({
                //         visible: true,
                //         message: error.data.error
                //     })
                // })
                // .then((sender) => {
                //     openPopup()
                // })
            }
        }
    }

        // if(props.gerarBoletoDataSource) {
        //     console.log(props.gerarBoletoDataSource.boleto)
        //     if(props.gerarBoletoDataSource.boleto){
        //         httpRequest('POST', requestsApi.getBoletoPDF, requestsApi.basicAuth, {
        //             "applicationId": applicationID,
        //             "relationId": relationId,
        //             "tituloBoletoId": props.gerarBoletoDataSource.boleto
        //         })
        //         .then((sender) => {
        //             const resultJson = JSON.parse(sender)
        //             console.log("entrou no then do getBoleto")
        //             console.log(resultJson.arquivo)
        //             convertBinaryForPDF(resultJson.arquivo)
        //         })
        //         .catch((error) => {console.log(error)}) 
        //     }
        // }
        // useEffect(() => {
        //     if(props.gerarBoletoDataSource){
        //         console.log(props.gerarBoletoDataSource)
        //     }
        // }, [props.gerarBoletoDataSource])

    const RenderRecordFormPopup = memo(() => {
        return (
            <RecordFormPopup
                type={"recordForm"}
                id={Math.random()}
                // idValue={props.masterEntityId}
                entityName={detailEntityName}
                caption={detailCaptionName}
                keyFieldNameState={keyFieldNameState ? keyFieldNameState : props.keyFieldNameState}
                masterEntityId={props.masterEntityId}
                popupRecordForm={true}
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
                popupState={popupState}
                setPopupState={setPopupState}
                popupRecordFormAdd={popupRecordFormAdd}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordFormOpen={popupRecordFormOpen}
                masterDetailEntity={dataEntity && dataEntity?.detailEntity}
                embebedIdValueState={embebedIdValueState}
                embebedEntityNameState={embebedEntityNameState}
            />
        );
    })

    const refreshDetailEntity = (entityNameGrid) => {
        httpRequest("POST", 
                requestsApi.getLookupTable, requestsApi.basicAuth,
                {
                    "lookUpEntities": [
                        {
                            "lookUpEntity": "_pontuacao_contato",
                        },
                        {
                            "lookUpEntity": "_tipo_contato",
                        }
                    ]
                }
            ).then((sender)=>{
                const senderDataLookup = JSON.parse(sender)

                setDataLookupState(senderDataLookup)
               
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, 
                    {
                        "applicationId": applicationID
                        ,"entityName": entityNameGrid
                        ,"fieldName": "_cadastro_id"
                        ,"expressao": props.masterEntityId
                    }
                )
                .then(senderGetDataEntity=>{
                const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                if(entityNameGrid === "_cadastro_contatos"){
                    setContatosDataSourceState(resultGetDataEntity?.masterEntity?.[0]?.records)

                }
                else if(entityNameGrid === "_cadastro_emails"){
                    setEmailsDataSourceState(resultGetDataEntity?.masterEntity?.[0]?.records)

                }
                })
                .catch((error) => {
                    setPopupStates({
                    visible: true,
                    message: error.data.error
                })
                })
            })
            .catch((error) => {
                setPopupStates({
                    visible: true,
                    message: error.data.error
                })
            })
    }
    const [popupStates, setPopupStates] = useState({
        visible: false,
        message: null
    })

    const hidePopupMessage = () => {
        setPopupStates({
          ...popupStates,
          visible: false
        })
      }

    const fieldsPermissionUpdateArray = []

    const recordInsertJsonDetail = (entityNameGrid, formData) => {
        delete formData.__KEY__;
        showLoadPanel();
        httpRequest("POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth,
        {
            "entityName": entityNameGrid,
            "json": formData,
            "applicationId": applicationID,
            "relationId": relationId
        })
        .then((sender) => {
            //const senderUpdateRecordFromJson = JSON.parse(sender)
            // props.modeEditOff()
            toastConfiguration(true, 'success', "Registro inserido com sucesso!")
            hideLoadPanel()
        })
        .catch((error) => {
            console.log(error)
        })
        // .catch((error) => {
        //     hideLoadPanel()
        //     if (error?.data?.error) {
        //         setMessageError(error.data.error)
        //         setPopup({
        //             ...popup
        //             , isVisible: true
        //             , message: "Atenção!"
        //         })
        //     }
        //     else {
        //         setMessageError(error?.statusText)
        //         setPopup({
        //             ...popup
        //             , isVisible: true
        //             , message: "Atenção!"
        //         })
        //     }
        // })
    }

    const recordUpdateJsonDetail = (entityNameGrid, jsonRow) => {
        if(jsonRow) {
            httpRequest("POST", 
            requestsApi.updateRecordFromJson, requestsApi.basicAuth, 
                {
                "entityName": entityNameGrid,
                "json": jsonRow,
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // props.modeEditOff()  
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)   
            })
            .catch((error) => {
                console.log(error)
            })
            // .catch((error)=>{
            //     if(error.data.error) {
            //         hideLoadPanel()
            //         setMessageError(error.data.error)
            //         setPopup({
            //             ...popup
            //                 ,isVisible: true
            //                 ,message: "Atenção!" 
            //         })
            //     }
            //     else {
            //         hideLoadPanel()
            //         setMessageError(error.statusText)
            //         setPopup({
            //             ...popup
            //                 ,isVisible: true
            //                 ,message: "Atenção!" 
            //         })
            //     } 
            // })
        }
    }

    const recordDeleteJsonDetail = (entityNameGrid, jsonRowId) => {
            httpRequest("POST", 
            requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
                {
                "entityName": entityNameGrid,
                "applicationId": applicationID,
                "relationId": relationId,
                "fieldName": 'id',
                "expressao": jsonRowId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // modeEditOff()  
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)   
            })
            // .catch((error)=>{
            //     if(error.data.error) {
            //         hideLoadPanel()
            //         setMessageError(error.data.error)
            //         setPopup({
            //             ...popup
            //                 ,isVisible: true
            //                 ,message: "Atenção!" 
            //         })
            //     }
            //     else {
            //         hideLoadPanel()
            //         setMessageError(error.statusText)
            //         setPopup({
            //             ...popup
            //                 ,isVisible: true
            //                 ,message: "Atenção!" 
            //         })
            //     } 
            // })
    }
      
    const addRowTelefone = useCallback(() => {
        refDataGridConsultaBoletoFormTelefones.current.instance.addRow();
        refDataGridConsultaBoletoFormTelefones.current.instance.deselectAll();
    }, [refDataGridConsultaBoletoFormTelefones]);
      
    const addRowEmail = useCallback(() => {
        refDataGridConsultaBoletoFormEmails.current.instance.addRow();
        refDataGridConsultaBoletoFormEmails.current.instance.deselectAll();
    }, [refDataGridConsultaBoletoFormEmails]);

    const handleBatchSaveTelefone = (e) => {
        const insertArr = [];
        const updateArr = [];
        const removeArr = [];
        
        const requestTypes = {
            insert: i => {
                const jsonDataInsert = i.data
                delete jsonDataInsert.__KEY__;
                const insertFormData = {
                    ...jsonDataInsert,
                    "_cadastro_id": props?.masterEntityId
                }; 
                insertArr.push(insertFormData);
            },
            update: i => {

                const dataObjPermissionUpdate = {
                    "id": i.key.id
                };

                const fieldsNotPermissionUpdateArray = []

                const jsonDataUpdate = i.data
                // delete jsonDataUpdate.id;

                const dataKeysArray = Object.keys(jsonDataUpdate)

                for (let c = 0; c <= dataKeysArray.length - 1; c++){
                    if (fieldsPermissionUpdateArray.find((e) => e === dataKeysArray[c])) {
                        dataObjPermissionUpdate[dataKeysArray[c]] = jsonDataUpdate[dataKeysArray[c]]
                    }
                    else{
                        fieldsNotPermissionUpdateArray.push(dataKeysArray[c])
                    }
                };
                jsonDataUpdate["id"] = i.key.id
                updateArr.push(jsonDataUpdate);
                // if (Object.keys(dataObjPermissionUpdate)?.length > 1){
                //     updateArr.push(dataObjPermissionUpdate);
                // } 
                // else {
                //     refreshDetailEntity(props.entityNameGrid, "_cadastro_id");
                // }

                // if(fieldsNotPermissionUpdateArray.length > 0){
                //     let mensagemErroCamposSemPermissao = "Não foi possível atualizar "

                //     for(let c = 0; c <= fieldsNotPermissionUpdateArray.length - 1; c++){
                //         mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + fieldsNotPermissionUpdateArray[c] + ", "
                //     }

                //     mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + "pois usuário não tem permissão."
                //     setPopupStates({
                //         visible: true,
                //         message: mensagemErroCamposSemPermissao
                //     })
                // }

            },
            remove: i => {
                const removeFormData = {
                    ...i.data,
                    "id": i.key
                };
                removeArr.push(removeFormData);
            }
        }


        for (let i of e.changes) {
            if (requestTypes[i.type]) {
                requestTypes[i.type](i);
            }
        }


        // * Faz a requisição para inserts e updates em lote
        if (insertArr.length > 0) {
            recordInsertJsonDetail("_cadastro_contatos", insertArr, refDataGridConsultaBoletoFormTelefones);
        }
        if (updateArr.length > 0) {
            recordUpdateJsonDetail("_cadastro_contatos", updateArr, refDataGridConsultaBoletoFormTelefones);
        }

        // * Só faz o refresh se tiver algum insert ou delete
        if (insertArr.length > 0 || removeArr.length > 0) { 
            refreshDetailEntity("_cadastro_contatos");
        }

        setModeEditDataGridTelefonesState(false)

    };

    const onRowInsertingTelefone = (e) => {
        console.log(e)                               
        if (Object.keys(e.data).length === 0) {
            e.cancel = true;
            notify({ message: "Não é permitido adicionar registros vazios" }, "error", 1500);
        }
    }

    const onRowRemovedTelefone = (e) => {
        recordDeleteJsonDetail("_cadastro_contatos", e.key.id)
        refreshDetailEntity("_cadastro_contatos")
        setModeEditDataGridTelefonesState(false)
    }

    const handleBatchSaveEmail = (e) => {
        const insertArr = [];
        const updateArr = [];
        const removeArr = [];
        
        const requestTypes = {
            insert: i => {
                const jsonDataInsert = i.data
                delete jsonDataInsert.__KEY__;
                const insertFormData = {
                    ...jsonDataInsert,
                    "_cadastro_id": props?.masterEntityId
                }; 
                insertArr.push(insertFormData);
            },
            update: i => {
                const dataObjPermissionUpdate = {
                    "id": i.key.id
                };

                const fieldsNotPermissionUpdateArray = []

                const jsonDataUpdate = i.data
                // delete jsonDataUpdate.__KEY__;

                const dataKeysArray = Object.keys(jsonDataUpdate)

                for (let c = 0; c <= dataKeysArray.length - 1; c++){
                    if (fieldsPermissionUpdateArray.find((e) => e === dataKeysArray[c])) {
                        dataObjPermissionUpdate[dataKeysArray[c]] = jsonDataUpdate[dataKeysArray[c]]
                    }
                    else{
                        fieldsNotPermissionUpdateArray.push(dataKeysArray[c])
                    }
                };
                jsonDataUpdate["id"] = i.key.id
                updateArr.push(jsonDataUpdate);

                // if (Object.keys(dataObjPermissionUpdate)?.length > 1){
                //     updateArr.push(dataObjPermissionUpdate);
                // } 
                // else {
                //     refreshDetailEntity(props.entityNameGrid);
                // }

                // if(fieldsNotPermissionUpdateArray.length > 0){
                //     let mensagemErroCamposSemPermissao = "Não foi possível atualizar "

                //     for(let c = 0; c <= fieldsNotPermissionUpdateArray.length - 1; c++){
                //         mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + fieldsNotPermissionUpdateArray[c] + ", "
                //     }

                //     mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + "pois usuário não tem permissão."
                //     setPopupStates({
                //         visible: true,
                //         message: mensagemErroCamposSemPermissao
                //     })
                // }

            },
            remove: i => {
                const removeFormData = {
                    ...i.data,
                    "id": i.key
                };
                removeArr.push(removeFormData);
            }
        }


        for (let i of e.changes) {
            if (requestTypes[i.type]) {
                requestTypes[i.type](i);
            }
        }


        // * Faz a requisição para inserts e updates em lote
        if (insertArr.length > 0) {
            recordInsertJsonDetail("_cadastro_emails", insertArr, refDataGridConsultaBoletoFormEmails);
        }
        if (updateArr.length > 0) {
            recordUpdateJsonDetail("_cadastro_emails", updateArr, refDataGridConsultaBoletoFormEmails);
        }

        // * Só faz o refresh se tiver algum insert ou delete
        if (insertArr.length > 0 || removeArr.length > 0) { 
            refreshDetailEntity("_cadastro_emails");
        }

        setModeEditDataGridEmailsState(false)

    };

    const onRowInsertingEmail = (e) => {
        console.log(e)                               
        if (Object.keys(e.data).length === 0) {
            e.cancel = true;
            notify({ message: "Não é permitido adicionar registros vazios" }, "error", 1500);
        }
    }

    const onRowRemovedEmail = (e) => {
        recordDeleteJsonDetail("_cadastro_emails", e.key.id)
        refreshDetailEntity("_cadastro_emails")
        setModeEditDataGridEmailsState(false)

    }




    
    return(
        <div style={{height: "81vh", padding: "0.5em"}}>
            <UpfPopupMessage
                hidePopup={hidePopupMessage}
                visible={popupStates.visible}
                message={popupStates.message}
            />

            <LoadPanel
                style={{ zIndex: "-1 !important", border: "solid 1px red !important" }}
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={600}
                height={60}
            />

            {popupRecordFormOpen && props.masterEntityId && <RenderRecordFormPopup />}
            {/* <ScrollView  width='100%' height='100%'> */}
                <Popup
                    visible={popup.isVisible}
                    onHiding={hidePopupBoleto}
                    dragEnabled={false}
                    showCloseButton={true}
                    showTitle={true}
                    title="Boleto"
                    fullScreen={true}
                    closeOnOutsideClick={false}
                    resizeEnabled={true}
                    >
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={closeButtonPopup}
                    />
                    {resultBinary &&
                        <embed
                            src={resultBinary.doc}
                            id="displayFile"
                            alt="your image"
                            width="100%"
                            height="99%"
                            style={{ borderStyle: "solid" }}
                            type="application/pdf"
                        />
                    }
                </Popup>
                {props.boletoId ? 
                    <>
                        <div style={{display: "flex"}}>
                            <div className="div-grupos-gerar-acordo">                    
                                <div style={{marginBottom: "0.5em"}}>
                                    Telefones:
                                </div>

                                <div className="btn-tabPanel">
                                    <div className="buttons-tabpanel">
                                        <Button            
                                            icon="add"
                                            hint={`Incluir um novo registro`}
                                            visible={permissoesContatosState ? permissoesContatosState?._insert: false}
                                            type="normal"
                                            onClick={() => {
                                                if(modeEditDataGridTelefonesState){
                                                    addRowTelefone()
                                                }
                                                else{
                                                    httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                                                        entityName: '_cadastro_contatos',
                                                        applicationId: applicationID,
                                                    })
                                                    .then((sender) => {
                                                        setEntityStructureRecordFormPopupState(JSON.parse(sender))

                                                        if(JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0){
                                                            
                                                            httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                                                                "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                                                            })
                                                            .then((sender) => {
                                                                setDataLookupRecordFormPopupState(JSON.parse(sender))

                                                                setKeyFieldNameState('_cadastro_id')
                                                                setIDValueDetailEntity(null)
                                                                setDetailEntityName('_cadastro_contatos')
                                                                // setDetailCaptionName(senderDetailEntity.caption)
                                                                setPopupRecordFormOpen(true)
                                                                setPopupState(true)
                                                                setStateModeEdit(true)
                                                            })
                                                            .catch((error) => {
                                                                setPopupStates({
                                                                    visible: true,
                                                                    message: error.data.error
                                                                })
                                                            })
                                                        }
                                                        else{
                                                            setKeyFieldNameState('_cadastro_id')
                                                            setIDValueDetailEntity(null)
                                                            setDetailEntityName('_cadastro_contatos')
                                                            // setDetailCaptionName(senderDetailEntity.caption)
                                                            setPopupRecordFormOpen(true)
                                                            setPopupState(true)
                                                            setStateModeEdit(true)
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        setPopupStates({
                                                            visible: true,
                                                            message: error.data.error
                                                        })
                                                    })
                                                }
                                            }
                                            }
                                        />
                                    </div>

                                    <div className="buttons-tabpanel">
                                        <Button
                                            icon="rowproperties"
                                            hint="Editar registros em lote no grid"
                                            type="normal"
                                            visible={permissoesContatosState ? permissoesContatosState?._edit: false}
                                            onClick={() => {
                                                setModeEditDataGridTelefonesState(!modeEditDataGridTelefonesState)
                                            }}
                                        />
                                    </div>

                                    <div className="buttons-tabpanel">
                                        <Button
                                            icon="refresh"
                                            hint="Atualizar dados do servidor"
                                            type="normal"
                                            onClick={() => refreshDetailEntity("_cadastro_contatos")}
                                        />
                                    </div>
                                    
                                </div>

                                <DataGrid
                                    ref={refDataGridConsultaBoletoFormTelefones}
                                    dataSource={contatosDataSourceState}
                                    //dataSource={[{teste: 1}, {teste: 2}]}
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    onRowInserting={onRowInsertingTelefone}
                                    onRowRemoved={onRowRemovedTelefone}
                                    onSaving={handleBatchSaveTelefone} 
                                    onSelectionChanged={(sender) => {
                                        setStates({...states,
                                            selectedItems: sender.component.getSelectedRowsData()})

                                    }}
                                >
                                    <Selection 
                                        mode="multiple"
                                        keyExpr='ID'
                                    />
                                    <Scrolling 
                                        rowRenderingMode='infinity'
                                        showScrollbar='always'
                                        >
                                    </Scrolling>

                                    <Paging defaultPageSize={6} />

                                    <Sorting
                                        mode="single"
                                    />
                                    
                                    <Pager
                                        visible={true}
                                        // allowedPageSizes={10}
                                        // showPageSizeSelector={true}
                                        showInfo={true}
                                        showNavigationButtons={true} />
                                    
                                    {/* <HeaderFilter
                                            allowSearch={true}
                                            visible={true}
                                        />
                                    <GroupPanel
                                        visible={true}
                                        allowColumnDragging={true}
                                    />
                                    <FilterRow visible={true} /> */}
                                    <Editing
                                        allowDeleting={modeEditDataGridTelefonesState && permissoesContatosState?._delete}
                                        allowUpdating={modeEditDataGridTelefonesState && permissoesContatosState?._edit}
                                        // allowAdding={modeEditDataGridTelefonesState && permissoesContatosState?._insert}
                                        confirmDelete={true}
                                        mode="batch"
                                        useIcons={true}
                                    //selectTextOnEditStart={selectTextOnEditStart}
                                    //startEditAction={startEditAction}
                                    />
                                    <Column
                                        caption="Deletar"
                                        visible={modeEditDataGridTelefonesState && permissoesContatosState?._delete}
                                        type="buttons"
                                        width={110}
                                    >
                                        <Button
                                            name="delete"
                                        />
                                    </Column>

                                    <Column
                                        dataField="_cryptfone"
                                        caption="Telefone"
                                    ></Column>

                                    <Column
                                        dataField="_tipocontatoid"
                                        caption="Tipo"
                                    >
                                        <Lookup
                                            dataSource={dataLookupState?._tipo_contato}
                                            valueExpr="id"
                                            displayExpr="_descricao"
                                        />               
                                    </Column>

                                    <Column
                                        dataField="_pontuacaocontatoid"
                                        caption="Pontuação"
                                    >
                                        <Lookup
                                            dataSource={dataLookupState?._pontuacao_contato}
                                            valueExpr="id"
                                            displayExpr="_descricao"
                                        />    
                                    </Column>

                                
                                </DataGrid>

                                <div className="div-boleto-form-vencimento">
                                    <Button
                                        width={130}
                                        height={40}
                                        text="Enviar SMS"
                                        // disabled={desabilitarBotoesState ? true : false}
                                        type="default"
                                        stylingMode="contained"
                                        onClick={() => {
                                            if(states.selectedItems.length > 0) {
                                                let arrayTelefones = []

                                                for(let c = 0; c <= states.selectedItems.length - 1; c++){
                                                    arrayTelefones.push(states.selectedItems[c]._cryptfone)
                                                }
                                                //enviar boleto por sms para selecionados
                                                httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
                                                    applicationId: applicationID,
                                                    relationId: relationId,
                                                    procedure: "_enviarBoletoSms",
                                                    json: {
                                                        telefones: arrayTelefones//telefones dos itens selecionados
                                                    },
                                                    idValue: [`${props.boletoId}`]//id do boleto
                                                })
                                                .then((sender) => {
                                                    const resultExecProcedureJsonParameterJson = JSON.parse(sender)
                                                    toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
                                                })
                                                .catch((error) => {
                                                    setPopupStates({
                                                        visible: true,
                                                        message: error.data.error
                                                    })
                                                })
                                            }
                                            else{
                                                setPopupStates({
                                                    visible: true,
                                                    message: 'Telefone não selecionado!'
                                                })
                                            }
                                        }}
                                    />

                                    <Button
                                        width={150}
                                        height={40}
                                        style={{marginRight: '5px'}}
                                        // disabled={desabilitarBotoesState ? true : false}
                                        text="Enviar WhatsApp"
                                        type="default"
                                        stylingMode="contained"
                                        onClick={() => {
                                            if(states.selectedItems.length > 0) {
                                                let arrayTelefones = []

                                                for(let c = 0; c <= states.selectedItems.length - 1; c++){
                                                    arrayTelefones.push(states.selectedItems[c]._cryptfone)
                                                }
                                                //enviar boleto por sms para selecionados
                                                httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
                                                    applicationId: applicationID,
                                                    relationId: relationId,
                                                    procedure: "_enviarBoletoWhatsApp",
                                                    json: {
                                                        telefones: arrayTelefones//telefones dos itens selecionados
                                                    },
                                                    idValue: [`${props.boletoId}`]//id do boleto
                                                })
                                                .then((sender) => {
                                                    const resultExecProcedureJsonParameterJson = JSON.parse(sender)
                                                    toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
                                                })
                                                .catch((error) => {
                                                    setPopupStates({
                                                        visible: true,
                                                        message: error.data.error
                                                    })
                                                })
                                            }
                                            else{
                                                setPopupStates({
                                                    visible: true,
                                                    message: 'Telefone não selecionado!'
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="div-grupos-gerar-acordo">                    
                                <div style={{marginBottom: "0.5em"}}>
                                    E-mails:
                                </div>

                                <div className="btn-tabPanel">
                                    <div className="buttons-tabpanel">
                                        <Button            
                                            icon="add"
                                            hint={`Incluir um novo registro`}
                                            visible={permissoesEmailsState ? permissoesEmailsState?._insert: false}
                                            type="normal"
                                            onClick={() => {
                                                if(modeEditDataGridEmailsState){
                                                    addRowEmail()
                                                }
                                                else{
                                                    httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                                                        entityName: "_cadastro_emails",
                                                        applicationId: applicationID,
                                                    })
                                                    .then((sender) => {
                                                        setEntityStructureRecordFormPopupState(JSON.parse(sender))

                                                        if(JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0){
                                                            
                                                            httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                                                                "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                                                            })
                                                            .then((sender) => {
                                                                setDataLookupRecordFormPopupState(JSON.parse(sender))

                                                                setKeyFieldNameState('_cadastro_id')
                                                                setIDValueDetailEntity(null)
                                                                setDetailEntityName("_cadastro_emails")
                                                                // setDetailCaptionName(senderDetailEntity.caption)
                                                                setPopupRecordFormOpen(true)
                                                                setPopupState(true)
                                                                setStateModeEdit(true)
                                                            })
                                                            .catch((error) => {
                                                                setPopupStates({
                                                                    visible: true,
                                                                    message: error.data.error
                                                                })
                                                            })
                                                        }
                                                        else{
                                                            setKeyFieldNameState('_cadastro_id')
                                                            setIDValueDetailEntity(null)
                                                            setDetailEntityName("_cadastro_emails")
                                                            // setDetailCaptionName(senderDetailEntity.caption)
                                                            setPopupRecordFormOpen(true)
                                                            setPopupState(true)
                                                            setStateModeEdit(true)
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        setPopupStates({
                                                            visible: true,
                                                            message: error.data.error
                                                        })
                                                    })
                                                }
                                            }
                                            }
                                        />
                                    </div>

                                    <div className="buttons-tabpanel">
                                        <Button
                                            icon="rowproperties"
                                            hint="Editar registros em lote no grid"
                                            type="normal"
                                            visible={permissoesEmailsState ? permissoesEmailsState?._edit: false}
                                            onClick={() => {
                                                setModeEditDataGridEmailsState(!modeEditDataGridEmailsState)
                                            }}
                                        />
                                    </div>

                                    <div className="buttons-tabpanel">
                                        <Button
                                            icon="refresh"
                                            hint="Atualizar dados do servidor"
                                            type="normal"
                                            onClick={() => refreshDetailEntity("_cadastro_emails")}
                                        />
                                    </div>
                                </div>

                                <DataGrid
                                    ref={refDataGridConsultaBoletoFormEmails}
                                    dataSource={emailsDataSourceState}
                                    //dataSource={[{teste: 1}, {teste: 2}]}
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    onRowInserting={onRowInsertingEmail}
                                    onRowRemoved={onRowRemovedEmail}
                                    onSaving={handleBatchSaveEmail} 
                                    onSelectionChanged={(sender) => {
                                        setEmailState(sender.component.getSelectedRowsData())
                                    }}
                                >
                                    <Selection 
                                        mode="multiple"
                                        keyExpr='ID'
                                    />
                                    <Scrolling 
                                        rowRenderingMode='infinity'
                                        showScrollbar='always'
                                        >
                                    </Scrolling>

                                    <Paging defaultPageSize={6} />

                                    <Sorting
                                        mode="single"
                                    />
                                    
                                    <Pager
                                        visible={true}
                                        // allowedPageSizes={10}
                                        // showPageSizeSelector={true}
                                        showInfo={true}
                                        showNavigationButtons={true} />
                                    
                                    {/* <HeaderFilter
                                            allowSearch={true}
                                            visible={true}
                                        />
                                    <GroupPanel
                                        visible={true}
                                        allowColumnDragging={true}
                                    />
                                    <FilterRow visible={true} /> */}

                                    <Editing
                                        allowDeleting={modeEditDataGridEmailsState && permissoesEmailsState?._delete}
                                        allowUpdating={modeEditDataGridEmailsState && permissoesEmailsState?._edit}
                                        // allowDeleting={modeEditDataGridEmailsState}
                                        // allowUpdating={modeEditDataGridEmailsState}
                                        // allowAdding={modeEditDataGridEmailsState && permissoesEmailsState?._insert}
                                        confirmDelete={true}
                                        mode="batch"
                                        useIcons={true}
                                    //selectTextOnEditStart={selectTextOnEditStart}
                                    //startEditAction={startEditAction}
                                    />
                                    <Column
                                        caption="Deletar"
                                        visible={modeEditDataGridEmailsState && permissoesEmailsState?._delete}
                                        // visible={modeEditDataGridEmailsState}
                                        type="buttons"
                                        width={110}
                                    >
                                        <Button
                                            name="delete"
                                        />
                                    </Column>

                                    <Column
                                        dataField="_cryptemail"
                                        caption="E-mail"
                                    ></Column>

                                    {/* <Column
                                        dataField="_tipocontatoid"
                                        caption="Tipo"
                                    >
                                        <Lookup
                                            dataSource={dataLookupState?._tipo_contato}
                                            valueExpr="id"
                                            displayExpr="_descricao"
                                        />               
                                    </Column>

                                    <Column
                                        dataField="_pontuacaocontatoid"
                                        caption="Pontuação"
                                    >
                                        <Lookup
                                            dataSource={dataLookupState?._pontuacao_contato}
                                            valueExpr="id"
                                            displayExpr="_descricao"
                                        />    
                                    </Column> */}

                                
                                </DataGrid>

                                <div className="div-boleto-form-vencimento">
                                <Button
                                    width={130}
                                    height={40}
                                    text="Enviar e-mail"
                                    // disabled={desabilitarBotoesState ? true : false}
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        if(emailState?.length > 0){
                                            const arrayEmailsTexto = []

                                            for(let c = 0; c <= emailState.length - 1; c++){
                                                arrayEmailsTexto.push(emailState[c]?._cryptemail)
                                            }
                                            //enviar boleto para o email
                                            httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, {
                                                applicationId: applicationID,
                                                relationId: relationId,
                                                procedure: "_enviarBoletoEmail",
                                                json: {
                                                    email: arrayEmailsTexto//email selecionado
                                                },
                                                idValue: [`${props.boletoId}`]//id do boleto
                                            })
                                            .then((sender) => {
                                                const resultExecProcedureJsonParameterJson = JSON.parse(sender)
                                                toastConfiguration(true, 'success', resultExecProcedureJsonParameterJson?.mensagem)
                                            })
                                            .catch((error) => {
                                                console.log(error?.data?.error)
                                                setPopupStates({
                                                    visible: true,
                                                    message: error?.data?.error ? error?.data?.error : 'E-mail inválido!'
                                                })
                                            })
                                        }
                                        else{
                                            setPopupStates({
                                                visible: true,
                                                message: "Selecione pelo menos um e-mail"
                                            })
                                        }
                                        
                                    }}
                                />
                                </div>
                            </div>
                        </div>

                        <div>
                                <button
                                    href="#"
                                    onClick={convertBinaryForPDF}
                                    style={{
                                        position: "relative",
                                        zIndex: 2,
                                        background: "transparent",
                                        border: "none"
                                    }}
                                >
                                    <Button
                                    width={140}
                                    height={40}
                                    // href="#"
                                    disabled={desabilitarBotoesState ? true : false}
                                    text="Visualizar Boleto"
                                    type="success"
                                    stylingMode="contained"
                                    // onClick={() => {
                                    //         //visualizar boleto
                                    //         console.log(props.boletoId)
                                    //         convertBinaryForPDF(props.boletoId)
                                    //     }
                                    // }
                                />
                                </button>
                            </div>

                        {/* {props?.arquivopdf ?
                        (
                            <>
                                <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "0.5em", marginBottom: "1em"}}>
                                    <div>
                                        <button
                                            href="#"
                                            onClick={convertBinaryForPDF}
                                            style={{
                                                position: "relative",
                                                zIndex: 2,
                                                background: "transparent",
                                                border: "none"
                                            }}
                                        >
                                            <Button
                                            width={140}
                                            height={40}
                                            // href="#"
                                            text="Visualizar Boleto"
                                            type="success"
                                            stylingMode="contained"
                                            // onClick={() => {
                                            //         //visualizar boleto
                                            //         console.log(props.boletoId)
                                            //         convertBinaryForPDF(props.boletoId)
                                            //     }
                                            // }
                                        />
                                        </button>
                                    </div>
                                    
                                    
                                    
                                    <div>
                                        <Button
                                            width={130}
                                            height={40}
                                            text="Anterior"
                                            type="default"
                                            stylingMode="contained"
                                            onClick={() => {
                                                
                                                props.setIndexMultiViewItemsGerarBoleto(props.indexMultiViewItemsGerarBoleto - 1)
                                            }}
                                        />
                                    </div>
                                </div>

                            </>
                        )
                        :
                        (
                            <div>
                                <button
                                    href="#"
                                    onClick={convertBinaryForPDF}
                                    style={{
                                        position: "relative",
                                        zIndex: 2,
                                        background: "transparent",
                                        border: "none"
                                    }}
                                >
                                    <Button
                                    width={140}
                                    height={40}
                                    // href="#"
                                    text="Visualizar Boleto"
                                    type="success"
                                    stylingMode="contained"
                                    // onClick={() => {
                                    //         //visualizar boleto
                                    //         console.log(props.boletoId)
                                    //         convertBinaryForPDF(props.boletoId)
                                    //     }
                                    // }
                                />
                                </button>
                            </div>
                        )
                    } */}

                        {props.boletoId && entityStructureRecordFormBoleto && props.consultaBoletoFormPopupState &&
                            <ScrollView width='100%' height='100%'>
                                <RecordForm
                                    id={Math.random()}
                                    idValue={props.boletoId}
                                    entityName={"_titulo_boleto"}
                                    dataLookup={dataLookupRecordFormBoleto}
                                    entityStructure={entityStructureRecordFormBoleto}
                                    embeddedComponents={true}
                                />
                            </ScrollView>
                        }
                    </>
                    : 
                    <Loading/>
                }
                {/* </ScrollView> */}
            
        </div>        
    )
}

export default ConsultaBoletoForm;