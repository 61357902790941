import { useState, useEffect, useRef, memo, useCallback } from 'react';
import DateBox from 'devextreme-react/date-box';
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Selection,
    Grouping,
    GroupPanel,
    Lookup,
    Pager,
    Paging,
    SearchPanel,
    Summary,
    SortByGroupSummaryInfo,
    FilterPanel,
    TotalItem
    } from 'devextreme-react/data-grid';
import { httpRequest } from '../../../../functions';
import { useRequestsApi } from '../../../../Context/RequestsApi';
import { applicationID } from '../../../../api/ApplicationID';
import ScrollView from 'devextreme-react/scroll-view';
import UpfPopupMessage from "../../../../CardComponents/UpfPopupMessage"
import { convertDate } from '../../../../functions/convertDate';
import { LoadPanel } from 'devextreme-react/load-panel';
import { calculateCustomSummary } from '../../../../functions/calculateCustomSummary';
import "../style.css"

const Passo1 = (props) => {
    console.log(props.entityStructureFieldsDefs)
    const {requestsApi} = useRequestsApi()
    const [states, setStates] = useState({
        dataSource: null,
        dataSourceMemo: "",
        tipoNegociacaoState: null,
        opcaoNegociacaoState: null,
        // arraySelecionados: []
    })
  
    const _dataGridParcelasRef = useRef(null)
    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );


    const hidePopup = () => {
        props.setVisible(false)
    }
    //---------------------------

    const [selectedRowKeysState, setSelectedRowKeysState] = useState([])

    const refreshDataGrid = (type, selecionados) => {
        _dataGridParcelasRef.current.instance.beginUpdate()
        console.log(selecionados)
        if (type === "Apenas a mais atrasada" || type === "Vencidas/atualização" || type === "Escolher parcelas") {
            const stateDataGridParcelas = _dataGridParcelasRef.current.instance.state()
            console.log(stateDataGridParcelas)
            stateDataGridParcelas.selectedRowKeys = selecionados
            console.log(stateDataGridParcelas)

            _dataGridParcelasRef.current.instance.state(stateDataGridParcelas)

        }
        else {
            _dataGridParcelasRef.current.instance.refresh()
            _dataGridParcelasRef.current.instance.selectAll()
        }
        _dataGridParcelasRef.current.instance.endUpdate()
    }


    const selecionarParcelas = (itemSelecionado) => {
        if(itemSelecionado){
            let parcelasSelecionadasOrdenadas = []
            for(let c = 0; c <= states.dataSource.length - 1; c++) {
                if(states.dataSource[c]._diasatraso >= itemSelecionado._diasatraso){
                    parcelasSelecionadasOrdenadas.push(states.dataSource.indexOf(states.dataSource[c]))
                }
                if(c === states.dataSource.length - 1){
                    refreshDataGrid("Escolher parcelas", parcelasSelecionadasOrdenadas)
                }
            }
        }
    }

    

    useEffect(() => {
        if(requestsApi && props.dateBoxState && props.indexMultiViewItemsGerarAcordo === 0 && props.dataSourceParcelasVencidasVincendasState) {
            setStates(
                {
                    ...states,
                    dataSource: props.dataSourceParcelasVencidasVincendasState,
                    dataSourceMemo: props.dataSourceParcelasVencidasVincendasState,
                    // arraySelecionados: props.arraySelecionadosState
                }
            )
        }
    }, [])


    const onSelectionChanged = (e) => e.component.refresh(true);
    

    const somarValoresAtualizacaoParcelasSelecionadas = () => {
        let result = 0

        try{
            const parcelasSelecionadas = _dataGridParcelasRef.current.instance.getSelectedRowsData()
    
            for(let c = 0; c <= parcelasSelecionadas.length - 1; c++){
                result = result + parcelasSelecionadas[c]._totalvaloratualizacao
            }
        }
        catch{

        }

        return result.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
    }

    return(
        <div style={{height: "81vh", padding: "0.5em"}}>
            {/* <ScrollView  width='100%' height='100%'> */}
                <UpfPopupMessage
                    hidePopup={hidePopup}
                    visible={props.visible}
                    message={props.message}
                />

                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={props.loadingPanelState.loadPanelVisible}
                    // visible={true}
                    showIndicator={props.loadingPanelState.showIndicator}
                    shading={props.loadingPanelState.shading}
                    showPane={props.loadingPanelState.showPane}
                />

                {props.showTitle &&
                    <h2 className="title-acordo">Acordo</h2>
                }
                <div className="dx-field">
                    <div className="dx-field-label">Vencimento da Entrada:</div>
                        <div className="dx-field-value">
                            <DateBox
                                disabled={props.loadingPanelState.loadPanelVisible}
                                acceptCustomValue={true}
                                activeStateEnabled={true}
                                adaptivityEnabled={false}
                                pickerType="calendar"
                                type="date"
                                //placeholder=""
                                dateSerializationFormat="yyyy-MM-dd"
                                value={props.dateBoxState}
                                onContentReady={() => {
                                    if(props.dateBoxState === null){
                                        let stringDate = new Date().toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit'})
                                        let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')
                                
                                        //junta o formato da data da segunda linha com a hora da primeira
                                        let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 19))
                                        props.setDateBoxState(stringDateResult)

                                    }
                                }}
                                onValueChanged={(sender) => {
                                    props.setDateBoxState(sender.value)

                                    if(props.dateBoxState){
                                        props.getParcelasVencidasVincendasFunction(sender.value)
                                    }
                                    //props.dateBoxState = sender.value.toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'})
                                }}
                                showDropDownButton={true}
                                spellcheck={false}
                                max={props.dataSourceParcelasVencidasVincendasState?.[0]?.acordoDataEntradaMaxima}
                                // value={props.dateBoxState && props.dateBoxState}
                                visible={true}
                            />
                        </div>
                </div>
                            
                <div>
                    <div className="dx-field">
                        <div className="dx-field-label">Opção de Negociação :</div>
                        <div className="dx-field-value">
                            <SelectBox
                                isRequired={true}
                                searchEnabled={false}
                                dataSource={props.dataLookup ? props.dataLookup["OpcaoNegociacao"] : props.dataLookup}
                                displayExpr="descricao"
                                valueExpr="id"
                                // defaultValue={4}
                                value={states.opcaoNegociacaoState && states.opcaoNegociacaoState.id}
                                wrapItemText={false}
                                onContentReady={() => {
                                    if(props.marcarTodasParcelas){
                                        states.opcaoNegociacaoState = {
                                            "descricao": "Total da dívida quitação",
                                            "id": 3
                                        }
                                    }
                                    else{
                                        states.opcaoNegociacaoState = {
                                            "id": 4,
                                            "descricao": "Escolher parcelas"
                                        }
                                    }
                                }}
                                onSelectionChanged={(sender) => {
                                    if (props.dataLookup) {
                                        _dataGridParcelasRef.current.instance.deselectAll()
                                        if (sender.selectedItem.descricao === "Apenas a mais atrasada") {
                                            let maisAtrasada = 0
                                            for (let c = 0; c <= states.dataSource.length - 1; c++) {
                                                if (states.dataSource[c]._diasatraso > maisAtrasada) {
                                                    maisAtrasada = states.dataSource[c]._diasatraso
                                                }
                                            }
                                            let resultMaiorAtraso = states.dataSource.find(element => element._diasatraso === maisAtrasada)


                                            if (maisAtrasada > 0 && resultMaiorAtraso !== -1) {
                                                refreshDataGrid("Apenas a mais atrasada", [resultMaiorAtraso.id])
                                                // refreshDataGrid("Apenas a mais atrasada", [states.dataSource.indexOf(resultMaiorAtraso)])
                                                setStates({
                                                    ...states,
                                                    // dataSource: resultMaiorAtraso,
                                                    opcaoNegociacaoState: sender.selectedItem,
                                                    // arraySelecionados: [resultMaiorAtraso]
                                                })
                                            }
                                            else {
                                                setStates({
                                                    ...states,
                                                    // dataSource: [],
                                                    opcaoNegociacaoState: sender.selectedItem
                                                })
                                                refreshDataGrid("Apenas a mais atrasada")
                                            }
                                        }

                                        else if(sender.selectedItem.descricao === "Vencidas/atualização"){
                                            let parcelasVencidasArrayIndices = []
                                            let parcelasVencidasArrayIds = []
                                            const arrayItensSelecionados = []

                                            
                                            for(let c = 0; c <= states.dataSource.length - 1; c++) {
                                                if(states.dataSource[c]._diasatraso > 0){
                                                    arrayItensSelecionados.push(states.dataSource[c])
                                                    parcelasVencidasArrayIds.push(states.dataSource[c].id)
                                                    // parcelasVencidasArrayIndices.push(states.dataSource.indexOf(states.dataSource[c]))
                                                }
                                                if(c === states.dataSource.length - 1){
                                                    refreshDataGrid("Vencidas/atualização", parcelasVencidasArrayIds)
                                                    // refreshDataGrid("Vencidas/atualização", parcelasVencidasArrayIndices)
                                                }
                                            }
                                            setStates({...states,
                                                opcaoNegociacaoState: sender.selectedItem,
                                                // arraySelecionados: arrayItensSelecionados
                                                // dataSource: parcelasVencidasArray
                                            })
                                            
                                        }
                                        else if (sender.selectedItem.descricao === "Total da dívida/quitação") {
                                            setStates({
                                                ...states,
                                                opcaoNegociacaoState: sender.selectedItem,
                                                // dataSource: states.dataSourceMemo
                                            })
                                            refreshDataGrid("Total da dívida/quitação")
                                        }
                                        else if (sender.selectedItem.descricao === "Escolher parcelas") {
                                            setStates({
                                                ...states,
                                                opcaoNegociacaoState: sender.selectedItem,
                                                // dataSource: states.dataSourceMemo
                                            })
                                            // refreshDataGrid("Escolher parcelas")
                                        }
                                    }
                                }}
                                // 
                            />
                        </div>
                    </div>
                </div>
                
                {states.opcaoNegociacaoState && 
                    <div>
                        <div className="div-parcelas">
                            Selecione as parcelas para emissão do boleto:

                            <div style={{ marginTop: '1em' }}>
                                <Button
                                    icon="columnchooser"
                                    hint="Mostrar ou esconder colunas do grid"
                                    type="normal"
                                    onClick={() => {
                                        _dataGridParcelasRef.current.instance.showColumnChooser()
                                    }}
                                />
                            </div>
                        </div>

                        <DataGrid
                            ref={_dataGridParcelasRef}
                            dataSource={states.dataSource ? (states.dataSource) : ([])}
                            columnAutoWidth={true}
                            showBorders={true}
                            cacheEnabled={false}
                            keyExpr={"id"}
                            columnResizingMode="widget"
                            rowAlternationEnabled={true}              
                            allowColumnResizing={true}
                            allowColumnReordering={true}
                            columnHidingEnabled={true}
                            columnMinWidth={50}
                            onSelectionChanged={onSelectionChanged}
                            onContentReady={() => {
                                if(states.opcaoNegociacaoState.id === 3){
                                    _dataGridParcelasRef.current.instance.selectAll()
                                }
                            }}
                            onCellClick={(sender) => {
                                if (states.opcaoNegociacaoState.descricao !== "Escolher parcelas") {
                                    setStates({
                                        ...states,
                                        opcaoNegociacaoState: {
                                            "id": 4,
                                            "descricao": "Escolher parcelas"
                                        }
                                    })
                                } 

                            }}
                        >

                            <HeaderFilter
                                allowSearch={true}
                                visible={true}
                            />

                            <FilterPanel 
                                visible={true} 
                            />

                            <FilterRow 
                                visible={true} 
                            />

                            <GroupPanel
                                visible={true}
                                allowColumnDragging={true}
                            />

                            <Selection 
                                mode="multiple"
                                keyExpr='id'
                                selectAllMode='allPages'
                                selectByClick={true}
                            />
                            <Scrolling 
                                // rowRenderingMode='infinity'
                                useNative={true}
                                showScrollbar='always'
                            />

                            <Paging defaultPageSize={10} />

                            <Sorting
                                mode="none"
                            />
                            
                            <Pager
                                visible={true}
                                // allowedPageSizes={6}
                                // showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true} />
                            
                            {/* <HeaderFilter
                                    allowSearch={true}
                                    visible={true}
                                />
                            <GroupPanel
                                visible={true}
                                allowColumnDragging={true}
                            />
                            <FilterRow visible={true} /> */}
                            
                            <ColumnChooser 
                                enabled={false} 
                                width={400} 
                                height={800} 
                                mode={"select"} 
                                allowSearch={true}
                            />

                            {props.entityStructureFieldsDefs.map((item, index) => {
                                
                                if (item.lookupkeyfield != null && props.dataLookupState) {
                                    console.log(props.dataLookupState)
                                    const lookupkeyfield = item.lookupkeyfield.toLowerCase()             
                                    const lookuplistsource = item.lookuplistsource.toLowerCase()
                                    const lookupresultfield = item.lookupresultfield.toLowerCase()
                                    const dataSourceFilter = []
                                    const arrayValues = []
                                    
                                    if(states.dataSource?.length){

                                        for(let i = 0; i <= states.dataSource.length - 1; i++){
                                            if(!arrayValues?.find((e) => e === states.dataSource[i][item.fieldname])){
                                                arrayValues.push(states.dataSource[i][item.fieldname])
                                            }
                                        }

                                        if(props.dataLookupState[lookuplistsource]?.length){
                                            for(let c = 0; c <= props.dataLookupState[lookuplistsource].length - 1; c++){
                                                if(arrayValues?.find((e) => e === props.dataLookupState[lookuplistsource][c][`${lookupkeyfield}`])){
                                                    dataSourceFilter.push({
                                                        text: props.dataLookupState[lookuplistsource][c][`${lookupresultfield}`], 
                                                        value: props.dataLookupState[lookuplistsource][c][`${lookupkeyfield}`], 
                                                    })
                                                }
                                            }
                                        }
                                    }
                                    
                                    return (
                                        <Column
                                            key={index}
                                            dataField={item.fieldname}
                                            caption={item.fieldcaption}
                                            visible={item.visible === 1}
                                            showInColumnChooser={item.showincolumnchooser === 1 || item.gridvisibleforcustomization === 1}
                                            defaultSortOrder={item.sortorder}
                                            defaultSortIndex={item.sortindex}  
                                        >
                                            <HeaderFilter
                                                allowSearch={true}
                                                visible={true}
                                                searchMode='contains'
                                                dataSource={dataSourceFilter}
                                                key={lookupkeyfield}
                                            />
                                            <Lookup
                                                dataSource={props.dataLookupState[lookuplistsource]}
                                                valueExpr={lookupkeyfield}
                                                displayExpr={lookupresultfield}
                                                allowClearing={true}
                                            />

                                        </Column>
                                    )
                                }
                                else if(item.editortype === 'dxDateBox'){
                                    return(
                                        <Column
                                            key={index}
                                            dataField={item.fieldname}
                                            caption={item.fieldcaption}
                                            dataType='date'
                                            visible={item.visible === 1}
                                            showInColumnChooser={item.showincolumnchooser === 1 || item.gridvisibleforcustomization === 1}
                                            defaultSortOrder={item.sortorder}
                                            defaultSortIndex={item.sortindex}                                        
                                        />
                                    )
                                }
                                else if(item.editortype === 'dxCheckBox'){
                                    return(
                                        <Column
                                            key={index}
                                            dataField={item.fieldname}
                                            caption={item.fieldcaption}
                                            dataType='boolean'
                                            visible={item.visible === 1}
                                            showInColumnChooser={item.showincolumnchooser === 1 || item.gridvisibleforcustomization === 1}
                                            defaultSortOrder={item.sortorder}
                                            defaultSortIndex={item.sortindex}
                                        />
                                    )
                                }
                                else if(item.format === 'money') {
                                    return(
                                        <Column
                                            key={index}
                                            dataField={item.fieldname}
                                            caption={item.fieldcaption}
                                            visible={item.visible === 1}
                                            showInColumnChooser={item.showincolumnchooser === 1 || item.gridvisibleforcustomization === 1}
                                            defaultSortOrder={item.sortorder}
                                            defaultSortIndex={item.sortindex}
                                            format="R$ #,##0.##;(R$ #,##0.##)"
                                        />
                                    )
                                }
                                else if (item.format === 'percent') {
                                    return (
                                        <Column
                                            key={index}
                                            dataField={item.fieldname}
                                            caption={item.fieldcaption}
                                            visible={item.visible === 1}
                                            showInColumnChooser={item.showincolumnchooser === 1 || item.gridvisibleforcustomization === 1}
                                            defaultSortOrder={item.sortorder}
                                            defaultSortIndex={item.sortindex}
                                            format="#0'%'"
                                        />
                                    )
                                }
                                else {
                                    return(
                                        <Column
                                            key={index}
                                            dataField={item.fieldname}
                                            caption={item.fieldcaption}
                                            visible={item.visible === 1}
                                            showInColumnChooser={item.showincolumnchooser === 1 || item.gridvisibleforcustomization === 1}
                                            defaultSortOrder={item.sortorder}
                                            defaultSortIndex={item.sortindex}
                                        />
                                    )
                                }
                                
                            })}
                            <Summary
                                calculateCustomSummary={calculateCustomSummary}
                            >
                                <TotalItem
                                    column={'_parcela'}
                                    summaryType="count"
                                    customizeText={(data) => `Qtd: ${data.value}`}
                                />
                                <TotalItem
                                    name='{"tipo": "count", "columnName": "_parcela"}'
                                    summaryType="custom"
                                    displayFormat="Sel.: {0}"
                                    showInColumn="_parcela"
                                />

                                {props.entityStructureFieldsDefs?.map((item, index) => {
                                        if (item.format === "money") {
                                            return (
                                                <TotalItem
                                                    column={item.fieldname}
                                                    summaryType="sum"
                                                    alignByColumn={true}
                                                    rowRenderingMode="standard"
                                                    format={{
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                        precision: 2,
                                                    }}
                                                    customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                />
                                            )
                                        }
                                    })
                                } 
                                {props.entityStructureFieldsDefs?.map((item, index) => {
                                        if (item.format === "money") {
                                            return (
                                                <TotalItem
                                                    name={`{"tipo": "value", "columnName": "${item.fieldname}"}`}
                                                    summaryType="custom"
                                                    customizeText={(data) => `Sel.: ${convertNumberToCurrency.format(data.value)}`}
                                                    format={{
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                        precision: 2,
                                                    }}
                                                    showInColumn={item.fieldname}
                                                />
                                            )
                                        }
                                    })
                                } 

                            </Summary>
                        </DataGrid>

                        {/* <div style={{display: "flex", justifyContent: "flex-start", position: "relative", bottom: "38px", }}>
                            <h5 style={{marginRight: "1em"}}>Quantidade selecionada: {_dataGridParcelasRef?.current?.instance?.getSelectedRowsData()?.length ? _dataGridParcelasRef?.current?.instance?.getSelectedRowsData()?.length : 0}</h5>
                            <h5>Total selecionado atualização: {somarValoresAtualizacaoParcelasSelecionadas()}</h5>
                        </div> */}
                        <div className="div-boleto-form-vencimento">
                        <Button
                            width={140}
                            height={40}
                            text="Próximo"
                            type="default"
                            disabled={props.disabledButtonState}
                            stylingMode="contained"
                            onClick={() => {
                                const itensSelecionados = _dataGridParcelasRef.current.instance.getSelectedRowsData()
                                console.log(itensSelecionados)
                                console.log(states.dataSource)

                                if(itensSelecionados.every((e) => e?._fasetaxadescricaoatualizacao) || itensSelecionados.every((e) => e?._fasetaxadescricaoquitacao)){
                                    if(itensSelecionados.length > 0) {
                                        let jsonStart = {
                                            applicationId: applicationID,
                                            tituloID : props.tituloId,//proximaFichaDados.tituloId,
                                            tipoNegociacao: 2, //states.tipoNegociacaoState.id = Acordo/Novação (id: 2)
                                            filtroOperadorLotePortal: 1,
                                            dataCalculo: props.dateBoxState,
                                            aVistaParcelado: 2
                                        }
                    
                                        //jsonStart["quitacaoAtualizacao"] = states.opcaoNegociacaoState.id === 3 ? 1 : 2 //1 é quitação 2 é atualização 

                                        if(itensSelecionados.length === states.dataSource.length) {                                            
                                            //QUITAÇÃO
                                            props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)
                                            jsonStart["quitacaoAtualizacao"] = 1 //1 é quitação 2 é atualização

                                            httpRequest("POST", requestsApi.getFaseCalculadaTitulo, requestsApi.basicAuth, jsonStart)
                                            .then((sender) => {
                                                const resultJsonData = JSON.parse(sender)
                                                convertDate(resultJsonData, [{
                                                    editortype: "dxDateBox",
                                                    fieldName: "_primeirovencimento"
                                                }])
            
                                                let dataSelecionada = props.dateBoxState
                                                let arrayDateResult = []
                                                for(let c = 0; c <= dataSelecionada.length - 1; c++) {
                                                    if(dataSelecionada[c] === "/"){
                                                        arrayDateResult.push("-")
                                                    } else {
                                                        arrayDateResult.push(dataSelecionada[c])
                                                    }
                                                }

                                                let arrayResult = {
                                                    vencimentoEntrada: arrayDateResult.join(""),
                                                    valor: 0,
                                                    multavalor: 0,
                                                    jurosvalor: 0,
                                                    honorariovalor: 0,
                                                    maiorAtraso: 0,
                                                    vencimento: 0,
                                                    total: 0,
                                                    tipoNegociacao: {id: 2},
                                                    parcelasSelecionadasId: [],
                                                    outrasDespesasValor: 0,
                                                    despesasPreJuridicoValor: 0,
                                                    cartorioValor: 0,
                                                    moraValor: 0,
                                                    ipcaValor: 0,
                                                    pontualidadeDescontoMaximo: 0
                                                }

                                                let honorarioCalculadoApenasPeloValorPrincipal = false
                                                let valoresPrincipaisParcelasVincendasSemHonorario = 0

                                                for(let c = 0; c < itensSelecionados.length; c++) {

                                                    if(itensSelecionados[c].id !== null){
                                                        arrayResult.parcelasSelecionadasId.push(itensSelecionados[c].id)
                                                    }

                                                    if(itensSelecionados[c]?._valor?.toFixed(2)){
                                                        arrayResult.valor = arrayResult.valor + parseFloat(itensSelecionados[c]._valor.toFixed(2))
                                                    }
                                                    if(itensSelecionados[c]?._multavalorquitacao?.toFixed(2)){
                                                        arrayResult.multavalor = arrayResult.multavalor + parseFloat(itensSelecionados[c]._multavalorquitacao.toFixed(2))
                                                    }
                                                  
                                                    if(itensSelecionados[c]?._jurosvalorquitacao?.toFixed(2)){
                                                        arrayResult.jurosvalor = arrayResult.jurosvalor + parseFloat(itensSelecionados[c]._jurosvalorquitacao.toFixed(2))
                                                    }
                                                    if(itensSelecionados[c]?._honorariovalorquitacao?.toFixed(2)){
                                                        arrayResult.honorariovalor = arrayResult.honorariovalor + parseFloat(itensSelecionados[c]._honorariovalorquitacao.toFixed(2))
                                                    }                    

                                                    if(itensSelecionados[c]?._outrasdespesasvalor?.toFixed(2)){
                                                        arrayResult.outrasDespesasValor = arrayResult.outrasDespesasValor + parseFloat(itensSelecionados[c]._outrasdespesasvalor.toFixed(2))
                                                    }

                                                    if(itensSelecionados[c]?._despesasprejuridicovalor?.toFixed(2)){
                                                        arrayResult.despesasPreJuridicoValor = arrayResult.despesasPreJuridicoValor + parseFloat(itensSelecionados[c]._despesasprejuridicovalor.toFixed(2))
                                                    }

                                                    if(itensSelecionados[c]?._cartoriovalor?.toFixed(2)){
                                                        arrayResult.cartorioValor = arrayResult.cartorioValor + parseFloat(itensSelecionados[c]._cartoriovalor.toFixed(2))
                                                    }

                                                    if(itensSelecionados[c]?._moravalorquitacao?.toFixed(2)){
                                                        arrayResult.moraValor = arrayResult.moraValor + parseFloat(itensSelecionados[c]._moravalorquitacao.toFixed(2))  
                                                    }

                                                    if(itensSelecionados[c]?._ipcavalorquitacao?.toFixed(2)){
                                                        arrayResult.ipcaValor = arrayResult.ipcaValor + parseFloat(itensSelecionados[c]._ipcavalorquitacao.toFixed(2))  
                                                    }

                                                    if(!itensSelecionados[c]._honorariosobreparcelasvincendas && itensSelecionados[c]._diasatraso <= 0){
                                                        valoresPrincipaisParcelasVincendasSemHonorario = valoresPrincipaisParcelasVincendasSemHonorario + itensSelecionados[c]._valor
                                                    }

                                                    if(itensSelecionados[c]?._descontopontualidade?.toFixed(2)){
                                                        arrayResult.pontualidadeDescontoMaximo = arrayResult.pontualidadeDescontoMaximo + parseFloat(itensSelecionados[c]._descontopontualidade.toFixed(2))
                                                    }

                                                    if(itensSelecionados[c]._honorariosobreprincipal){
                                                        honorarioCalculadoApenasPeloValorPrincipal = true
                                                    }

                                                    arrayResult.total = arrayResult.total 
                                                    + itensSelecionados[c]._valor 
                                                    + itensSelecionados[c]._multavalorquitacao 
                                                    + itensSelecionados[c]._jurosvalorquitacao 
                                                    + (itensSelecionados[c]._outrasdespesasvalor ? itensSelecionados[c]._outrasdespesasvalor : 0)
                                                    + (itensSelecionados[c]._despesasprejuridicovalor ? itensSelecionados[c]._despesasprejuridicovalor : 0)
                                                    + (itensSelecionados[c]._cartoriovalor ? itensSelecionados[c]._cartoriovalor : 0)
                                                    + (itensSelecionados[c]._moravalorquitacao ? itensSelecionados[c]._moravalorquitacao : 0)
                                                    + itensSelecionados[c]._honorariovalorquitacao
                                                    + itensSelecionados[c]._ipcavalorquitacao

                                                    if(arrayResult?.total?.toFixed(2)){
                                                        arrayResult.total = parseFloat(arrayResult.total.toFixed(2))
                                                    }
                                                    else{
                                                        arrayResult.total = 0
                                                    }
                                                    
                                                    if(itensSelecionados[c].diasatraso > arrayResult.maiorAtraso) {
                                                        arrayResult.maiorAtraso = itensSelecionados[c].diasatraso
                                                        arrayResult.vencimento = itensSelecionados[c].vencimento
                                                    }
                                                }
            
                                                props.setGerarAcordoDataSource({
                                                    arrayTotais: arrayResult,
                                                    resultgetFaseCalculadaTituloJsonData: resultJsonData[0],
                                                    parcelasSelecionadasId: arrayResult.parcelasSelecionadasId,                                                    
                                                    email: null,
                                                    telefones: [],
                                                    boleto: null,
                                                    honorarioCalculadoApenasPeloValorPrincipal: honorarioCalculadoApenasPeloValorPrincipal,
                                                    valoresPrincipaisParcelasVincendasSemHonorario: valoresPrincipaisParcelasVincendasSemHonorario
                                                })
                                            })
                                            .catch((error) => {
                                                console.log(error)
                                                props.setMessage(error.data.error)
                                                props.setVisible(true)
                                            })
                                        }
                                        else if (itensSelecionados.length < states.dataSource.length) {                                            
                                            //ATUALIZAÇÃO
                                            props.setIndexMultiViewItemsGerarAcordo(props.indexMultiViewItemsGerarAcordo + 1)

                                            const arrayParcelasAtrasadas = []

                                            for(let c = 0; c <= states.dataSource.length - 1; c++) {
                                                if(states.dataSource[c]._diasatraso > 0){
                                                    arrayParcelasAtrasadas.push(states.dataSource[c])
                                                }
                                            }
                                            
                                            if(itensSelecionados.every((e) => e._diasatraso > 0) && itensSelecionados?.length !== arrayParcelasAtrasadas?.length){
                                                jsonStart["quitacaoAtualizacao"] = 3 //1 é quitação 2 é atualização 3 é parcial
                                            }
                                            else{
                                                jsonStart["quitacaoAtualizacao"] = 2 //1 é quitação 2 é atualização 3 é parcial
                                            }
                                            httpRequest("POST", requestsApi.getFaseCalculadaTitulo, requestsApi.basicAuth, jsonStart)
                                            .then((sender) => {
                                                const resultJsonData = JSON.parse(sender)
                                                convertDate(resultJsonData, [{
                                                    editortype: "dxDateBox",
                                                    fieldName: "_primeirovencimento"
                                                }])
                                                
                                                let dataSelecionada = props.dateBoxState
                                                    let arrayDateResult = []
                                                    for(let c = 0; c <= dataSelecionada.length - 1; c++) {
                                                        if(dataSelecionada[c] === "/"){
                                                            arrayDateResult.push("-")
                                                        } else {
                                                            arrayDateResult.push(dataSelecionada[c])
                                                        }
                                                    }

                                                let arrayResult = {
                                                    vencimentoEntrada: arrayDateResult.join(""), 
                                                    valor: 0,
                                                    multavalor: 0,
                                                    jurosvalor: 0,
                                                    honorariovalor: 0,
                                                    maiorAtraso: 0,
                                                    vencimento: 0,
                                                    total: 0,
                                                    tipoNegociacao: {id: 2},
                                                    parcelasSelecionadasId: [],
                                                    outrasDespesasValor: 0,
                                                    despesasPreJuridicoValor: 0,
                                                    cartorioValor: 0,
                                                    moraValor: 0,
                                                    ipcaValor: 0,
                                                    pontualidadeDescontoMaximo: 0
                                                }
                                                
                                                let honorarioCalculadoApenasPeloValorPrincipal = false
                                                let valoresPrincipaisParcelasVincendasSemHonorario = 0

                                                for(let c = 0; c < itensSelecionados.length; c++) {

                                                    if(itensSelecionados[c].id !== null){
                                                        arrayResult.parcelasSelecionadasId.push(itensSelecionados[c].id)
                                                    }
                                                    if(itensSelecionados[c]?._valor?.toFixed(2)){
                                                        arrayResult.valor = arrayResult.valor + parseFloat(itensSelecionados[c]._valor.toFixed(2))
                                                    }
                                                    if(itensSelecionados[c]?._multavaloratualizacao?.toFixed(2)){
                                                        arrayResult.multavalor = arrayResult.multavalor + parseFloat(itensSelecionados[c]._multavaloratualizacao.toFixed(2))
                                                    }
                                                    if(itensSelecionados[c]?._jurosvaloratualizacao?.toFixed(2)){
                                                        arrayResult.jurosvalor = arrayResult.jurosvalor + parseFloat(itensSelecionados[c]._jurosvaloratualizacao.toFixed(2))
                                                    }
                                                    if(itensSelecionados[c]?._honorariovaloratualizacao?.toFixed(2)){
                                                        arrayResult.honorariovalor = arrayResult.honorariovalor + parseFloat(itensSelecionados[c]._honorariovaloratualizacao.toFixed(2))
                                                    }                            

                                                    if(itensSelecionados[c]?._outrasdespesasvalor?.toFixed(2)){
                                                        arrayResult.outrasDespesasValor = arrayResult.outrasDespesasValor + parseFloat(itensSelecionados[c]._outrasdespesasvalor.toFixed(2))
                                                    }

                                                    if(itensSelecionados[c]?._despesasprejuridicovalor?.toFixed(2)){
                                                        arrayResult.despesasPreJuridicoValor = arrayResult.despesasPreJuridicoValor + parseFloat(itensSelecionados[c]._despesasprejuridicovalor.toFixed(2))
                                                    }

                                                    if(itensSelecionados[c]?._cartoriovalor?.toFixed(2)){
                                                        arrayResult.cartorioValor = arrayResult.cartorioValor + parseFloat(itensSelecionados[c]._cartoriovalor.toFixed(2))
                                                    }

                                                    if(itensSelecionados[c]?._moravaloratualizacao?.toFixed(2)){
                                                        arrayResult.moraValor = arrayResult.moraValor + parseFloat(itensSelecionados[c]._moravaloratualizacao.toFixed(2))  
                                                    }
                                                    
                                                    if(itensSelecionados[c]?._ipcavaloratualizacao?.toFixed(2)){
                                                        arrayResult.ipcaValor = arrayResult.ipcaValor + parseFloat(itensSelecionados[c]._ipcavaloratualizacao.toFixed(2))  
                                                    }

                                                    if(!itensSelecionados[c]._honorariosobreparcelasvincendas && itensSelecionados[c]._diasatraso <= 0){
                                                        valoresPrincipaisParcelasVincendasSemHonorario = valoresPrincipaisParcelasVincendasSemHonorario + itensSelecionados[c]._valor
                                                    }

                                                    if(itensSelecionados[c]?._descontopontualidade?.toFixed(2)){
                                                        arrayResult.pontualidadeDescontoMaximo = arrayResult.pontualidadeDescontoMaximo + parseFloat(itensSelecionados[c]._descontopontualidade.toFixed(2))
                                                    }

                                                    if(itensSelecionados[c]._honorariosobreprincipal){
                                                        honorarioCalculadoApenasPeloValorPrincipal = true
                                                    }

                                                    arrayResult.total = arrayResult.total 
                                                    + itensSelecionados[c]._valor 
                                                    + itensSelecionados[c]._multavaloratualizacao 
                                                    + itensSelecionados[c]._jurosvaloratualizacao 
                                                    + (itensSelecionados[c]._outrasdespesasvalor ? itensSelecionados[c]._outrasdespesasvalor : 0)
                                                    + (itensSelecionados[c]._despesasprejuridicovalor ? itensSelecionados[c]._despesasprejuridicovalor : 0)
                                                    + (itensSelecionados[c]._cartoriovalor ? itensSelecionados[c]._cartoriovalor : 0)
                                                    + (itensSelecionados[c]._moravaloratualizacao ? itensSelecionados[c]._moravaloratualizacao : 0)
                                                    + itensSelecionados[c]._honorariovaloratualizacao
                                                    + itensSelecionados[c]._ipcavaloratualizacao

                                                    if(arrayResult?.total?.toFixed(2)){
                                                        arrayResult.total = parseFloat(arrayResult.total.toFixed(2))
                                                    }
                                                    else{
                                                        arrayResult.total = 0
                                                    }
                                                    
                                                    if(itensSelecionados[c].diasatraso > arrayResult.maiorAtraso) {
                                                        arrayResult.maiorAtraso = itensSelecionados[c].diasatraso
                                                        arrayResult.vencimento = itensSelecionados[c].vencimento
                                                    }
                                                }

                                                props.setGerarAcordoDataSource({
                                                    arrayTotais: arrayResult,
                                                    resultgetFaseCalculadaTituloJsonData: resultJsonData[0],
                                                    parcelasSelecionadasId: arrayResult.parcelasSelecionadasId,                                                    
                                                    email: null,
                                                    telefones: [],
                                                    boleto: null,
                                                    honorarioCalculadoApenasPeloValorPrincipal: honorarioCalculadoApenasPeloValorPrincipal,
                                                    valoresPrincipaisParcelasVincendasSemHonorario: valoresPrincipaisParcelasVincendasSemHonorario
                                                })
                                            })
                                            .catch((error) => {
                                                console.log(error)
                                                props.setMessage(error.data.error)
                                                props.setVisible(true)
                                            })
                                        }
                                        props.setJsonRequest(jsonStart);
                                    }
                                    else {
                                        props.setMessage("Nenhum item foi selecionado!")
                                        props.setVisible(true)
                                    }
                                }
                                else{
                                    props.setMessage('"Fase-Taxas" não localizada na configuração do credor para parcela selecionada.')
                                    props.setVisible(true)
                                }
                            }}
                        ></Button>
                        </div>
                    </div>
                }
            {/* </ScrollView> */}
        </div>
    )
}

export default memo(Passo1);